import apiClient from "../axios";

export default {
    getCertificados() {
        return apiClient.get("/certificados");
    },

    getCertificadosActivos() {
        return apiClient.get("/certificados/activos");
    },

    createCertificado(data) {
        return apiClient.post("/certificados", data);
    },

    updateCertificado(id, data) {
        return apiClient.put(`/certificados/${id}`, data);
    },

    desactivarCertificados(id) {
        return apiClient.put(`/certificados/desactivar/${id}`);
    },
};
