<template>
    <div class="card">
        <div class="card-header pb-0">
            <h6>Lecciones</h6>
            <button class="btn btn-primary ml-auto" @click="nuevoItem">NUEVO</button>
        </div>
        <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-0">
                <table class="table mb-0">
                    <thead>
                        <tr>
                            <th class="text-secondary opacity-7">ID</th>
                            <th class="text-secondary opacity-7">Título</th>
                            <th class="text-secondary opacity-7">Descripción</th>
                            <th class="text-center text-secondary opacity-7">Opciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="leccion in lecciones" :key="leccion.id">
                            <td>{{ leccion.id }}</td>
                            <td>{{ leccion.titulo }}</td>
                            <td>{{ leccion.descripcion }}</td>
                            <td class="text-center">
                                <button @click="verLeccion(leccion)" class="badge badge-sm bg-gradient-success">
                                    <i class="far fa-eye" aria-hidden="true"></i>
                            </button>
                            <button @click="editarLeccion(leccion)" class="badge badge-sm bg-gradient-warning">
                                <i class="far fa-edit" aria-hidden="true"></i> 
                            </button>
                            <button @click="desactivarLeccion(leccion.id)" class="badge badge-sm bg-gradient-danger">
                                <i class="far fa-trash-alt" aria-hidden="true"></i>
                            </button>
                            <button @click="verMaterial(leccion)" class="badge badge-sm bg-gradient-info">
                                <i class="fas fa-book" aria-hidden="true"></i>
                            </button>
                            <button @click="verSesion(leccion)" class="badge badge-sm bg-gradient-primary">
                                <i class="fas fa-chalkboard-teacher" aria-hidden="true"></i>
                            </button>
                        </td>
            </tr>
        </tbody>
    </table>
</div>
</div>

<div class="modal fade" id="principalModal" tabindex="-1" aria-labelledby="principalModalLabel" aria-hidden="true">
<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="principalModalLabel">
                {{ modalTitle }}
            </h5>
            <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            @click="closeModal"
            ></button>
        </div>
        <div class="modal-body">
            <form id="leccionForm">
                <div class="mb-3">
                    <label for="titulo" class="form-label">Título de la Lección</label>
                    <input type="text" class="form-control" id="titulo" v-model="form.titulo" />
                    <div class="text-danger">{{ formErrors.titulo }}</div>
                </div>
                <div class="mb-3">
                    <label for="descripcion" class="form-label">Descripción</label>
                    <textarea class="form-control" id="descripcion" v-model="form.descripcion"></textarea>
                    <div class="text-danger">{{ formErrors.descripcion }}</div>
                </div>
            </form>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closeModal">
                Cerrar
            </button>
            <button type="button" class="btn btn-primary" @click="guardarLeccion">
                Guardar
            </button>
        </div>
    </div>
</div>
</div>
</div>
</template>

<script>
import LeccioneService from "../../services/LeccioneService"; 
export default {
    name: "Lecciones",
    data() {
    return {
        lecciones: [], 
        cargando: false, 
        id_modulo: this.$route.params.id_modulo, 
        form: {
            titulo: "", 
            descripcion: "", 
            estado: 1, 
            id_modulo: this.$route.params.id_modulo, 
        },
        formErrors: {}, 
        modalTitle: "", 
        currentLeccioneId: null, 
    };
},
created() {
        this.fetchLecciones();
    },
    methods: {
        async fetchLecciones() {
            try {
                this.cargando = true;
                const response = await LeccioneService.getLeccionesPorModulo(this.id_modulo);
                this.lecciones = response.data.data; // Asigna las lecciones recibidas
            } catch (error) {
                console.error("Error al cargar las lecciones:", error);
                this.$swal({
                    icon: "error",
                    title: "Error",
                    text: "Hubo un error al cargar las lecciones.",
                });
            } finally {
                this.cargando = false; // Finaliza el estado de carga
            }
        },
        verLeccion(leccion) {
            const detalles = `
                <p><strong>Título:</strong> ${leccion.titulo}</p>
                <p><strong>Descripción:</strong> ${leccion.descripcion}</p>
            `;
            
            this.$swal({
                icon: "info",
                title: "Detalles de la Lección",
                html: detalles,
                width: "600px",
            });
        },
        editarLeccion(leccion) {
            this.modalTitle = "Editar Lección";
            this.currentLeccioneId = leccion.id;
            this.form = {
                titulo: leccion.titulo,
                descripcion: leccion.descripcion,
                estado: leccion.estado,
                id_modulo: leccion.id_modulo,
            };
            this.showModal();
        },
                            async desactivarLeccion(id) {
                                try {
                                    await LeccioneService.desactivarLeccion(id);
                                    this.$swal({
                                        icon: "success",
                                        title: "Lección Desactivada",
                                        text: "La lección ha sido desactivada.",
                                    }).then(() => {
                                        this.fetchLecciones();
                                    });
                                } catch (error) {
                                    console.error("Error al desactivar la lección:", error);
                                    this.$swal({
                                        icon: "error",
                                        title: "Error",
                                        text: "Hubo un error al desactivar la lección.",
                                    });
                                }
                            },
                            async guardarLeccion() {
            if (this.currentLeccioneId) {
                // Actualizar lección existente
                try {
                    await LeccioneService.updateLecciones(this.currentLeccioneId, this.form);
                    this.fetchLecciones();
                    this.closeModal();
                    this.$swal({
                        icon: "success",
                        title: "Lección actualizada",
                        text: "La lección se ha actualizado correctamente.",
                    });
                } catch (error) {
                    console.error("Error al actualizar la lección:", error);
                    this.$swal({
                        icon: "error",
                        title: "Error",
                        text: "Hubo un error al actualizar la lección.",
                    });
                }
            } else {
                // Crear nueva lección
                try {
                    await LeccioneService.createLecciones(this.form);
                    this.fetchLecciones();
                    this.closeModal();
                    this.$swal({
                        icon: "success",
                        title: "Lección creada",
                        text: "La lección se ha creado correctamente.",
                    });
                } catch (error) {
                    console.error("Error al crear la lección:", error);
                    this.$swal({
                        icon: "error",
                        title: "Error",
                        text: "Hubo un error al crear la lección.",
                    });
                }
            }
        },
                
                            nuevoItem() {
                                this.modalTitle = "Nueva Lección";
                                this.currentLeccioneId = null;
                                this.form = {
                                    titulo: "",
                                    descripcion: "",
                                    estado: 1,
                                    id_modulo: this.form.id_modulo,
                                };
                                this.showModal();
                            },
                            showModal() {
                                const modal = document.getElementById("principalModal");
                                modal.classList.add("show");
                                modal.style.display = "block";
                                modal.setAttribute("aria-modal", "true");
                                modal.removeAttribute("aria-hidden");
                            },
                            closeModal() {
                                const modal = document.getElementById("principalModal");
                                modal.classList.remove("show");
                                modal.style.display = "none";
                                modal.setAttribute("aria-hidden", "true");
                                modal.removeAttribute("aria-modal");
                            },
                        },
                        };
                        </script>
                        <style>
                    </style>
