<template>
    <div class="card">
        <div class="card-header ">
            <h6>Evaluaciones</h6>
            <button class="btn btn-primary ml-auto" @click="nuevoItem()">Nuevo</button>     
            <Preloader :visible="cargando" />  

            <div class="table-responsive p-4">
              <table ref="itemtabla" class="table mb-0">
                <thead>
                  <tr>
                    <th class="text-center text-secondary opacity-7">ID</th>
                    <th class="text-start text-secondary opacity-7">Nombre</th>
                    <th class="text-start text-secondary opacity-7">Curso/Módulo</th>
                    <th class="text-center text-secondary opacity-7">Fecha de Apertura</th>
                    <th class="text-center text-secondary opacity-7">Fecha de Cierre</th>
                    <th class="text-center text-secondary opacity-7">Intentos</th>
                    <th class="text-center text-secondary opacity-7">Duración (Min)</th>
                    <th class="text-center text-secondary opacity-7">Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="evaluacion in evaluaciones" :key="evaluacion.id">
                    <td class="text-center">{{ evaluacion . id }}</td>
                    <td class="text-start">{{ evaluacion . nombre }}</td>
                    <td class="text-start">{{ evaluacion . curso }} {{ evaluacion . modulo }}</td>
                    <td class="text-center">{{ evaluacion . fecha_apertura }}</td>
                    <td class="text-center">{{ evaluacion . fecha_cierre }}</td>
                    <td class="text-center">{{ evaluacion . intentos }}</td>
                    <td class="text-center">{{ evaluacion . duracion }}</td>
                    <td class="text-center">
                      
                      <button @click="verEvaluacion(evaluacion)" class="badge badge-sm bg-gradient-success">
                        <i class="far fa-eye" aria-hidden="true"></i>
                      </button>
                      <button @click="editarEvaluacion(evaluacion)" class="badge badge-sm bg-gradient-warning">
                        <i class="far fa-edit" aria-hidden="true"></i>
                      </button>
                      <button @click="desactivarEvaluacion(evaluacion.id)" class="badge badge-sm bg-gradient-danger">
                        <i class="far fa-trash-alt" aria-hidden="true"></i>
                      </button>
                      <button @click="verPreguntas(evaluacion.id)" class="badge badge-sm bg-gradient-info">
                       Preguntas
                      </button>
                      <button @click="verDetalleEvaluaciones(evaluacion.id)" class="badge badge-sm bg-gradient-primary">
                        Estudiantes
                      </button>
                      
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
  <div v-if="isModalVisible" class="modal" tabindex="-1" aria-labelledby="principalModalLabel"
  aria-hidden="true">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title" id="principalModalLabel">{{ modalTitle }}</h5>
              <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
              <form @submit.prevent="saveItem">
                  <!-- Usamos un contenedor de grid con filas y columnas -->
                  <div class="row g-3"> <!-- g-3 para un espaciado uniforme entre las columnas -->

                      <!-- Tipo de Certificado -->
                      <div class="col-md-6">
                          <div class="mb-3">
                              <label class="form-label">Tipo de Evaluacion</label>
                              <div class="d-flex">
                                  <div class="form-check me-3">
                                      <input class="form-check-input" type="radio" id="tipo_curso"
                                          v-model="form.tipo_evaluacion" value="curso"
                                          @change="limpiarSelectModuloCurso" />
                                      <label class="form-check-label" for="tipo_curso">Curso</label>
                                  </div>
                                  <div class="form-check">
                                      <input class="form-check-input" type="radio" id="tipo_modulo"
                                          v-model="form.tipo_evaluacion" value="modulo"
                                          @change="limpiarSelectModuloCurso" />
                                      <label class="form-check-label" for="tipo_modulo">Módulo</label>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <!-- Selección de Curso -->
                      <div class="col-md-12">
                          <div class="mb-3">
                              <label for="curso" class="form-label">Selecciona un Curso</label>
                              <select id="curso" class="form-select" v-model="form.selectedCurso"
                                  @change="fetchModulosByCurso" required>
                                  <option v-for="curso in cursos" :key="curso.id"
                                      :value="curso.id">{{ curso . nombre }}</option>
                              </select>
                          </div>
                      </div>

                      <!-- Selección de Módulo (solo cuando tipo_certificado es 'modulo') -->
                      <div class="col-md-12" v-if="form.tipo_evaluacion === 'modulo'">
                          <div class="mb-3">
                              <label for="modulo" class="form-label">Selecciona un Módulo</label>
                              <select id="modulo" class="form-select" v-model="form.selectedModulo"
                                  @change="cargarDatosM" required>
                                  <option v-for="modulo in modulos" :key="modulo.id"
                                      :value="modulo.id">{{ modulo . nombre }}</option>
                              </select>
                          </div>
                      </div>
                      <!-- Nota -->
                      <div class="col-md-6">
                          <div class="mb-3">
                              <label for="nombre" class="form-label">Nombre</label>
                              <input id="nombre" v-model="form.nombre" class="form-control"
                                  type="text" min="0" max="10" step="0.1" required />
                          </div>
                      </div>
                      <!-- Fecha de Emisión -->
                      <div class="col-md-6">
                          <div class="mb-3">
                              <label for="fecha_apertura" class="form-label">Fecha de Apertura</label>
                              <input id="fecha_apertura" v-model="form.fecha_apertura" class="form-control"
                                  type="date" required />
                          </div>
                      </div>
                      <!-- Fecha de Inicio -->
                      <div class="col-md-6">
                          <div class="mb-3">
                              <label for="fecha_cierre" class="form-label">Fecha de Cierre</label>
                              <input id="fecha_cierre" v-model="form.fecha_cierre" class="form-control"
                                  type="date" required />
                          </div>
                      </div>
                      <!-- Código de Certificado -->
                      <div class="col-md-6">
                          <div class="mb-3">
                              <label for="intentos" class="form-label">Intentos </label>
                              <input id="intentos" v-model="form.intentos"
                                  class="form-control" type="number" required />
                          </div>
                      </div>
                        <!-- Código de Certificado -->
                        <div class="col-md-6">
                          <div class="mb-3">
                              <label for="duracion" class="form-label">Duración (Minutos)</label>
                              <input id="duracion" v-model="form.duracion"
                                  class="form-control" type="number" required />
                          </div>
                      </div>

                  </div> <!-- Fin de la fila (row) -->

              </form>
          </div>
          <div class="modal-footer">
              <button type="button" class="btn btn-secondary" @click="closeModal">Cerrar</button>
              <button type="button" class="btn btn-primary" @click="guardarEvaluacion">Guardar</button>
          </div>
      </div>
  </div>
</div>

</template>

<script>
    import Preloader from "../components/Preloader.vue";
    import EvaluacionService from "../../services/EvaluacionService";
    import CursoService from "../../services/CursoService";
    import ModuloService from "../../services/ModuloService";
    
    import $ from 'jquery';

    export default {
        name: "Evaluaciones",
        components: {
            Preloader
        },
        data() {
            return {
                evaluaciones: [],
                id_curso: this.$route.params.idcurso,
                cursos: [],
                modulos: [],
                alumnos:[],
                cargando: false,
                preguntas: [],
                form: {
                    id_curso: null,
                    id_modulo: null,
                    nombre: '',
                    descripcion: '-',
                    tipo_evaluacion: '', 
                    fecha_apertura: '',
                    fecha_cierre: '',
                    intentos: 0,
                    duracion: 0,
                    estado: 1,  
                    selectedCurso:null,
                    selectedModulo:null    
                },
                formErrors: '',
                modalTitle: 'Nueva Evaluación',
                currentItemId: null,
                isModalVisible: false,
                principalModalLabel: '',
            };
        },
        created() {
            this.fetchEvaluaciones();
            this.fetchCursos();
        },
        mounted() {
            // this.fetchModulos();
        },
        methods: {
        
        limpiarSelectModuloCurso() {              
            this.form.selectedCurso = null; 
            this.form.selectedModulo = null; 
            },
            verPreguntas(id) {
                this.$router.push({
                    name: "Preguntas",
                    params: {
                    idevaluacion: id
                    },
                })
            },
            verDetalleEvaluaciones(id){
                this.$router.push({
                    name: "DetalleEvaluaciones",
                    params: {
                        idcurso:this.id_curso,
                        idevaluacion: id
                    },
                })
            },
          async fetchModulosByCurso() {
              if (this.form.tipo_evaluacion === 'curso') {
                  try {
                      const response = await CursoService.getCurso(this.form.selectedCurso);
                                    
                      this.cursos= response.data.data;
                      console.log(this.cursos);         
                  } catch (error) {
                      console.error("Error fetching curso:", error);
                  }
              } else if (this.form.tipo_evaluacion === 'modulo') {
                  if (this.form.selectedCurso) {
                      try {
                          const response = await ModuloService.getModulosPorCurso(this.form.selectedCurso);
                          this.modulos = response.data.data;
                          console.log("Módulos obtenidos:", this.modulos);
                      } catch (error) {
                          console.error("Error fetching modulos:", error);
                      }
                  }
              }
          },
          async desactivarEvaluacion(id) {
            try {
                await EvaluacionService.desactivarEvaluacion(id);
                this.$swal({
                    icon: "success",
                    title: "Desactivado con éxito",
                }).then(() => {
                    location.reload();
                });
            } catch (error) {
                console.error("Error al desactivar:", error);
                this.$swal({
                    icon: "error",
                    title: "Error al desactivar",
                    text: error
                });
            }
        },

          async guardarEvaluacion() {
    console.log('Formulario:', this.form);

    // Validar que los campos necesarios estén completos
    if (!this.form.nombre || !this.form.fecha_apertura || !this.form.fecha_cierre) {
        this.$swal({
            icon: "error",
            title: "Error",
            text: "Por favor, completa todos los campos obligatorios.",
        });
        return;
    }

    // Ajustar curso/módulo según el tipo de evaluación
    if (this.form.tipo_evaluacion == "modulo") {
        if (!this.form.selectedModulo) {
            this.$swal({
                icon: "error",
                title: "Error",
                text: "Debes seleccionar un módulo.",
            });
            return;
        }
        this.form.id_modulo = this.form.selectedModulo;
        this.form.id_curso = null; // Limpiar el curso si es módulo
    } else if (this.form.tipo_evaluacion === "curso") {
        if (!this.form.selectedCurso) {
            this.$swal({
                icon: "error",
                title: "Error",
                text: "Debes seleccionar un curso.",
            });
            return;
        }
        this.form.id_curso = this.form.selectedCurso;
        this.form.id_modulo = null; // Limpiar el módulo si es curso
    }

    try {
        if (this.currentItemId) {
            // Actualizar evaluación existente
            await EvaluacionService.updateEvaluacion(this.currentItemId, this.form);
            this.$swal({
                icon: "success",
                title: "Actualizado",
                text: "La evaluación se actualizó correctamente.",
            });
        } else {
            // Crear nueva evaluación
            await EvaluacionService.createEvaluacion(this.form);
            this.$swal({
                icon: "success",
                title: "Registrado",
                text: "La evaluación se creó correctamente.",
            });
        }
        this.closeModal(); // Cerrar el modal
        this.fetchEvaluaciones(); // Recargar la lista de evaluaciones
    } catch (error) {
        console.error("Error al guardar la evaluación:", error);
        this.$swal({
            icon: "error",
            title: "Error",
            text: "Ocurrió un error al guardar la evaluación.",
        });
    }
},
verEvaluacion(evaluacion) {
    const detalles = `
        <div style="display: flex; flex-wrap: wrap;">
            <!-- Primera columna -->
            <div style="flex: 1; min-width: 50%; text-align: left; padding-right: 10px;">
                <p><strong>ID de la Evaluación:</strong> ${evaluacion.id}</p>
                <p><strong>Nombre:</strong> ${evaluacion.nombre}</p>
                <p><strong>Curso/Módulo:</strong> ${evaluacion.curso || ""} ${evaluacion.modulo || ""}</p>
                <p><strong>Fecha de Apertura:</strong> ${evaluacion.fecha_apertura}</p>
                <p><strong>Fecha de Cierre:</strong> ${evaluacion.fecha_cierre}</p>
                <p><strong>Intentos:</strong> ${evaluacion.intentos}</p>
                <p><strong>Duración:</strong> ${evaluacion.duracion} minutos</p>
            </div>
            <!-- Segunda columna -->
            <div style="flex: 1; min-width: 50%; text-align: left; padding-left: 10px;">
                <p><strong>Estado:</strong> ${evaluacion.estado ? "Activo" : "Inactivo"}</p>
                <p><strong>Creado:</strong> ${evaluacion.created_at}</p>
                <p><strong>Actualizado:</strong> ${evaluacion.updated_at}</p>
            </div>
        </div>
    `;

    this.$swal({
        icon: "info",
        title: "Detalles de la Evaluación",
        html: detalles,
        customClass: {
            popup: "swal-wide",
        },
        width: "800px", // Ajustar el ancho si es necesario
    });
},
            async fetchEvaluaciones() {
                try {
                    this.cargando = true;
                    const response = await EvaluacionService.getEvaluacionesPorCurso(this.id_curso);
                    console.log(response.data.data); // Verifica la estructura de los datos
                    this.evaluaciones = response.data.data || [];
                    console.log(this.evaluaciones);
                    
                } catch (error) {
                    console.error("Error fetching evaluaciones:", error);
                } finally {
                    this.cargando = false;
                }
            },
            async fetchCursos() {
                try {
                    const response = await CursoService.getCursos();
                    this.cursos = response.data.data;
                } catch (error) {
                    console.error("Error fetching cursos:", error);
                }
            },
            async fetchModulos() {
                try {
                    const response = await ModuloService.getModulos();
                    this.modulos = response.data.data;
                } catch (error) {
                    console.error("Error fetching modulos:", error);
                }
            },
            nuevoItem() {
                console.log('hola');
                this.modalTitle = "Crear nueva Evaluación";
                this.currentItemId = null;
                this.form = {
                    id_curso: null,
                    id_modulo: null,
                    nombre: '',
                    descripcion: '',
                    tipo_evaluacion: '',
                    fecha_apertura: '',
                    fecha_cierre: '',
                    intentos: 3,
                    duracion: 20,
                    estado: 1
                };
                this.isModalVisible = true;
            },
            editarEvaluacion(evaluacion) {
    // Configurar el modal para edición
    this.modalTitle = "Editar Evaluación";
    this.currentItemId = evaluacion.id;

    // Llenar el formulario con los datos de la evaluación seleccionada
    this.form = {
        id_curso: evaluacion.id_curso,
        id_modulo: evaluacion.id_modulo,
        nombre: evaluacion.nombre,
        descripcion: evaluacion.descripcion || '-', // Valor por defecto si no hay descripción
        tipo_evaluacion: evaluacion.id_modulo ? 'modulo' : 'curso', // Determinar tipo de evaluación
        fecha_apertura: evaluacion.fecha_apertura,
        fecha_cierre: evaluacion.fecha_cierre,
        intentos: evaluacion.intentos,
        duracion: evaluacion.duracion,
        estado: evaluacion.estado,
        selectedCurso: evaluacion.id_curso, // Sincronizar curso seleccionado
        selectedModulo: evaluacion.id_modulo, // Sincronizar módulo seleccionado
    };

    // Si la evaluación es de tipo módulo, cargar los módulos del curso seleccionado
    if (this.form.tipo_evaluacion === "modulo" && this.form.id_curso) {
        this.fetchModulosByCurso().then(() => {
            const moduloEncontrado = this.modulos.find(
                (modulo) => modulo.id === evaluacion.id_modulo
            );
            if (moduloEncontrado) {
                this.form.selectedModulo = moduloEncontrado.id;
            }
        });
    }

    // Mostrar el modal de edición
    this.isModalVisible = true;
},
            closeModal() {
                this.isModalVisible = false;
            },
            async saveItem() {
                // Validaciones
                if (!this.form.nombre || !this.form.descripcion || !this.form.fecha_apertura || !this.form
                    .fecha_cierre) {
                    this.$swal({
                        icon: "error",
                        title: "Error",
                        text: "Por favor, completa todos los campos obligatorios.",
                    });
                    return;
                }

                const request = await this.currentItemId ?
                    EvaluacionService.updateEvaluacion(this.currentItemId, this.form) :
                    EvaluacionService.createEvaluacion(this.form);

                request
                    .then(() => {
                        //this.closeModal();
                       // this.fetchEvaluaciones(); // Recargar la lista de evaluaciones
                    })
                    .catch((error) => {
                        console.error("Error al guardar evaluación:", error);
                        this.$swal({
                            icon: "error",
                            title: "Error",
                            text: "Hubo un error al guardar la evaluación.",
                        });
                    });
            },
            showModal() {
                const modal = $("#evaluacionModal");
                modal.addClass("show");
                modal.css("display", "block");
                modal.attr("aria-modal", "true");
                modal.removeAttr("aria-hidden");
            }
        },
    };
</script>
<style scoped>
    .modal {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        justify-content: center;
        align-items: center;
    }

    .modal-dialog {
        max-width: 500px;
        margin: auto;
    }

    .modal-content {
        background: white;
        border-radius: 8px;
        padding: 20px;
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
</style>
