<script setup>
import { onBeforeUnmount, onBeforeMount } from "vue";
import { useStore } from "vuex";

const store = useStore();
onBeforeMount(() => {
  store.state.hideConfigButton = true;
  store.state.showNavbar = false;
  store.state.showSidenav = false;
  store.state.showFooter = false;
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
});
</script>

<template>
  <div class="d-flex justify-content-center align-items-center" style="height: 100vh">
    <div class="row">
      <!---->
      <div class="col-md-6 imagen-pc align-items-center" >
        <img
          src="@/assets/img/logos/sesion.png"
          class="img-fluid"
          alt="Joven en Laptop"
        />
      </div>
      <div class="col-md-6">
        <div class="row justify-content-center">
          <div class="card">
            <div class="card-header text-center">
              <h3>{{ vistaRecuperacion ? 'Recuperar Contraseña' : 'Iniciar Sesión' }}</h3>
            </div>
            <div class="card-body">
              <form v-if="!vistaRecuperacion" @submit.prevent="handleLogin">
                <div class="mb-3">
                  <label for="email" class="form-label">Correo Electrónico</label>
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    v-model="form.email"
                    required
                    placeholder="Ingrese su correo"
                  />
                </div>
                <div class="mb-3">
                  <label for="password" class="form-label">Contraseña</label>
                  <input
                    type="password"
                    class="form-control"
                    id="password"
                    v-model="form.password"
                    required
                    placeholder="Ingrese su contraseña"
                  />
                </div>

                <button type="submit" class="btn btn-primary w-100">
                  Iniciar Sesión
                </button>
                <Preloader :visible="cargando" />
              </form>

              <form v-else @submit.prevent="recuperarContrasena">
                <div class="mb-3">
                  <label for="emailRecuperacion" class="form-label">Correo Electrónico</label>
                  <input
                    type="email"
                    class="form-control"
                    id="emailRecuperacion"
                    v-model="emailRecuperacion"
                    required
                    placeholder="Ingrese su correo para recuperar la contraseña"
                  />
                </div>
                <button type="submit" class="btn btn-primary w-100">
                  Enviar Código
                </button>
                <div class="mt-3">
                  <p class="text-muted">
                    Si no recibiste el código, comunícate con el administrador:
                  </p>
                  <p>Correo: esformacion@gmail.com</p>
                  <p>Teléfono: 950 467 271</p>
                </div>
              </form>

              <a @click="toggleVistaRecuperacion" class="text-center mt-3">
                {{ vistaRecuperacion ? 'Volver a Iniciar Sesión' : 'Recuperar Contraseña' }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "../../services/AuthService";
import Preloader from "../components/Preloader.vue";

export default {
  name: "Login",
  data() {
    return {
      user: this.$route.params.user,
      form: {
        email: "",
        password: "",
      },
      emailRecuperacion: "",
      cargando: false,
      vistaRecuperacion: false,
    };
  },
  methods: {
    async handleLogin() {
  try {
    this.cargando = true;
    const response = await AuthService.login(this.form);

    if (response && response.data) {
      const user = response.data.user;
      localStorage.setItem("authToken", response.data.token);
      localStorage.setItem("user", JSON.stringify(user));
      this.$store.commit("setUser", user);

      this.$router.push(
        { name: "Home" }
      );

    } else {
      throw new Error("Error en la respuesta del servidor");
    }
  } catch (error) {
    this.$swal({
      icon: "error",
      title: "Error",
      text: error.response?.data?.message || error,
    });
  } finally {
    this.cargando = false;
  }
},
    async handleRecuperarContrasena() {
      // recuperar la contraseña
      console.log("Recuperar contraseña para:", this.emailRecuperacion);
      // enviar el correo de recuperación
    },
    toggleVistaRecuperacion() {
      this.vistaRecuperacion = !this.vistaRecuperacion; 
    },
  },
  
};


</script>

<style scoped>
@media (min-width: 320px) and (max-width: 480px) {
  .imagen-pc {
    display: none;
  }
}
</style>