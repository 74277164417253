<script setup>
import { useRoute, useRouter } from "vue-router"; // Se añade `useRouter` para manejar la redirección tras cerrar sesión
import SidenavItem from "./SidenavItem.vue";

const getRoute = () => {
  const route = useRoute();
  const routeArr = route.path.split("/");
  return routeArr[1];
};

// Función para cerrar sesión
const router = useRouter(); // Instancia de router para manejar redirecciones
const CerrarSesion = () => {
  localStorage.removeItem("authToken"); // Elimina el token de autenticación
  localStorage.removeItem("user"); // Elimina la información del usuario
  router.push({ name: "Login" }); // Redirige a la página de inicio o login
};
</script>

<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <!-- Inicio -->
      <li class="nav-item">
        <sidenav-item
          to="/cliente-categorias"
          :class="getRoute() === 'cliente-categorias' ? 'active' : ''"
          :navText="'Categorías'"
        >
          <template v-slot:icon>
            <i class="bi bi-list text-custom fs-5 text-sm opacity-10"></i> <!-- Icono de Categorías -->
          </template>
        </sidenav-item>
      </li>
      <!-- Usuarios -->
      <li class="nav-item">
        <sidenav-item
          to="/cliente-usuarios"
          :class="getRoute() === 'cliente-usuarios' ? 'active' : ''"
          :navText="'Usuarios'"
        >
          <template v-slot:icon>
            <i class="bi bi-people text-custom fs-5 text-sm opacity-10"></i> <!-- Icono de Usuarios -->
          </template>
        </sidenav-item>
      </li>
      <!-- Cursos -->
      <li class="nav-item">
        <sidenav-item
          to="/cliente-cursos"
          :class="getRoute() === 'cliente-cursos' ? 'active' : ''"
          :navText="'Cursos'"
        >
          <template v-slot:icon>
            <i class="bi bi-journal-bookmark text-custom fs-3 text-sm opacity-10"></i> <!-- Icono de Cursos -->
          </template>
        </sidenav-item>
      </li>
      <!-- Certificado -->
      <li class="nav-item">
        <sidenav-item
          to="/cliente-certificado"
          :class="getRoute() === 'cliente-certificado' ? 'active' : ''"
          :navText="'Certificado'"
        >
          <template v-slot:icon>
            <i class="bi bi-award text-custom fs-5 text-sm opacity-10"></i> <!-- Icono de Certificado -->
          </template>
        </sidenav-item>
      </li>
      <!-- Evaluaciones -->
      <li class="nav-item">
        <sidenav-item
          to="/cliente-evaluaciones"
          :class="getRoute() === 'cliente-evaluaciones' ? 'active' : ''"
          :navText="'Evaluaciones'"
        >
          <template v-slot:icon>
            <i class="bi bi-file-text text-custom fs-5 text-sm opacity-10"></i> <!-- Icono de Evaluaciones -->
          </template>
        </sidenav-item>
      </li>
    
      <!-- Clientes 
      <li class="nav-item">
        <sidenav-item
          to="/cliente-clientes"
          :class="getRoute() === 'cliente-clientes' ? 'active' : ''"
          :navText="'Clientes'"
        >
          <template v-slot:icon>
            <i class="bi bi-person text-custom fs-5 text-sm opacity-10"></i> 
          </template>
        </sidenav-item>
      </li>-->
    
      <li class="nav-item">
        <sidenav-item
          to="/cliente-notificaciones"
          :class="getRoute() === 'cliente-clientes' ? 'active' : ''"
          :navText="'Notificaciones'"
        >
          <template v-slot:icon>
            <i class="bi bi-bell text-custom fs-5 text-sm opacity-10"></i> 
          </template>
        </sidenav-item>
      </li> 
      <!-- Cerrar Sesión -->
      <li class="nav-item mt-auto text-center">
        <div style="">
          <a href="#" @click="CerrarSesion" class=" btn btn-danger "> <!-- Color personalizado -->
            <i class="bi bi-power fs-5 me-2"></i> <!-- Icono de cerrar sesión -->
            Cerrar Sesión
          </a>
        </div>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.text-custom {
  color: #007bff; /* Color personalizado para los íconos en el menú */
}
.text-danger {
  color: #dc3545; /* Color rojo para el enlace de Cerrar Sesión */
}
</style>
