<template> 
    <div class="card">
        <div class="card-header pb-0 d-flex align-items-center justify-content-between">
            <h6>Usuarios</h6>
            <Preloader :visible="cargando" />
            <div class="m-4">
                <p>Importación masiva de usuarios</p>
                <input class="form-control" type="file" @change="onFileChange" />
                <button class="btn btn-primary" @click="uploadFile" :disabled="!file">Subir archivo Excel de Usuarios</button>
                <a href="https://app.micampusenlinea.com/template/plantillas.rar" target="_blank">Descargar Plantilla</a>
           </div>
            <button class="btn btn-primary ml-auto" @click="nuevoItem()">NUEVO USUARIO</button>
        </div>
        <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-4">
                <table class="table mb-0" ref="itemtabla">
                    <thead>
                        <tr>
                            <th class="text-start text-secondary opacity-7">N° DOC</th>
                            <th class="text-start text-secondary opacity-7">Nombre</th>
                            <th class="text-start text-secondary opacity-7">Apellido</th>
                            <th class="text-start text-secondary opacity-7">Correo</th>
                            <th class="text-secondary opacity-7">Celular</th>
                            <th class="text-secondary opacity-7">Profesión</th>
                            <th class="text-center text-secondary opacity-7">Opciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="usuario in usuarios" :key="usuario.id">
                            <td class="text-start ">{{ usuario.numero_documento }}</td>
                            <td class="text-start ">{{ usuario.nombres }}</td>
                            <td class="text-start ">{{ usuario.apellidos }}</td>
                            <td class="text-start ">{{ usuario.correo }}</td>
                            <td>{{ usuario.telefono }}</td>
                            <td>{{ usuario.profesion }}</td>
                            <td class="text-center">
                                <button @click="verUsuario(usuario)" class="badge badge-sm bg-gradient-success">
                                    <i class="far fa-eye" aria-hidden="true"></i>
                                </button>
                                <button @click="editarUsuario(usuario)" class="badge badge-sm bg-gradient-warning">
                                    <i class="far fa-edit" aria-hidden="true"></i>
                                </button>
                                <button @click="desactivarUsuario(usuario.id)" class="badge badge-sm bg-gradient-danger">
                                    <i class="far fa-trash-alt" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="modal fade modal-dark" id="principalModal" data-bs-backdrop="true" tabindex="-1"
            aria-labelledby="principalModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="principalModalLabel">{{ modalTitle }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal"></button>
                    </div>
                    
                    <!-- CUERPO DEL MODAL -->
                    <div class="modal-body">
                        <form id="premioForm">
                            <div class="row">
                                <!-- Columna 1 -->
                                <div class="col-12 col-md-6">
                                    <div class="row">
                                        <!-- Campo Nombre de Alumno -->
                                        <div class="col-12 mb-3">
                                            <label for="nombres" class="form-label">Nombre de Alumno</label>
                                            <input type="text" class="form-control" id="nombres" v-model="form.nombres" />
                                            <div class="text-danger">{{ formErrors.nombres }}</div>
                                        </div>
                                        <!-- Campo Apellidos -->
                                        <div class="col-12 mb-3">
                                            <label for="apellidos" class="form-label">Apellidos</label>
                                            <input type="text" class="form-control" id="apellidos" v-model="form.apellidos" />
                                            <div class="text-danger">{{ formErrors.apellidos }}</div>
                                        </div>
                                        <div class="col-12 mb-3">
                                            <label for="tipo_documento" class="form-label">Tipo de Documento</label>
                                            <select name="tipo_documento" class="form-control" id="tipo_documento" v-model="form.tipo_documento">
                                                <option value="DNI">DNI</option>
                                                <option value="PASAPORTE">PASAPORTE</option>
                                                <option value="OTRO">OTRO</option>
                                            </select>
                                            <div class="text-danger">{{ formErrors.tipo_documento }}</div>
                                        </div>
                                        <!-- Campo Número de Documento -->
                                        <div class="col-12 mb-3">
                                            <label for="numero_documento" class="form-label">Número de Documento</label>
                                            <input type="number" class="form-control" id="numero_documento" v-model="form.numero_documento" />
                                            <div class="text-danger">{{ formErrors.numero_documento }}</div>
                                        </div>
                                        <!-- Campo Código de Alumno -->
                                        <div class="col-12 mb-3">
                                            <label for="codigo_alumno" class="form-label">Código de Alumno</label>
                                            <input type="text" class="form-control" id="codigo_alumno" v-model="form.codigo_alumno" />
                                            <div class="text-danger">{{ formErrors.codigo_alumno }}</div>
                                        </div>
                                        <!-- Campo Ciudad -->
                                        <div class="col-12 mb-3">
                                            <label for="ciudad" class="form-label">Ciudad</label>
                                            <input type="text" class="form-control" id="ciudad" v-model="form.ciudad" />
                                            <div class="text-danger">{{ formErrors.ciudad }}</div>
                                        </div>
                                        <div class="col-12 mb-3">
                                            <label for="direccion" class="form-label">Dirección</label>
                                            <input type="text" class="form-control" id="direccion" v-model="form.direccion" />
                                            <div class="text-danger">{{ formErrors.direccion }}</div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <!-- Campo Apellidos -->
                                        
                                        <!-- Campo Tipo de Documento -->
                                        
                                        <!-- Campo Celular -->
                                        <div class="col-12 mb-3">
                                            <label for="telefono" class="form-label">Celular</label>
                                            <input type="number" class="form-control" id="telefono" v-model="form.telefono" />
                                            <div class="text-danger">{{ formErrors.telefono }}</div>
                                        </div>
                                        <!-- Campo Correo -->
                                    
                                        <!-- Campo Fecha de naciemiento -->
                                        <div class="col-12 mb-3">
                                            <label for="fecha_nacimiento" class="form-label">Fecha de nacimiento</label>
                                            <input type="date" class="form-control" id="fecha_nacimiento" v-model="form.fecha_nacimiento" />
                                            <div class="text-danger">{{ formErrors.fecha_nacimiento }}</div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Columna 2 -->
                                <div class="col-12 col-md-6">
                                    <div class="row">
                                        <!-- Campo Profesión -->
                                        <div class="col-12 mb-3">
                                            <label for="profesion" class="form-label">Profesión</label>
                                            <input type="text" class="form-control" id="profesion" v-model="form.profesion" />
                                            <div class="text-danger">{{ formErrors.profesion }}</div>
                                        </div>
                                        <!-- Campo Dirección -->
                                    
                                        <div class="col-12 mb-3">
                                            <label for="correo" class="form-label">Correo</label>
                                            <input type="email" class="form-control" id="correo" v-model="form.correo" />
                                            <div class="text-danger">{{ formErrors.correo }}</div>
                                        </div>
                                        <!-- Campo Contraseña -->
                                        <div class="col-12 mb-3">
                                            <label for="password" class="form-label">Contraseña</label>
                                            <input type="password" class="form-control" id="password" v-model="form.password" />
                                            <div class="text-danger">{{ formErrors.password }}</div>
                                        </div>
                                        <!-- Campo Repetir Contraseña -->
                                        <div class="col-12 mb-3">
                                            <label for="password_confirmation" class="form-label">Repetir Contraseña</label>
                                            <input type="password" class="form-control" id="password_confirmation" v-model="form.password_confirmation" />
                                            <div class="text-danger">{{ formErrors.password_confirmation }}</div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <!-- Campo Foto de Perfil -->
                                        <div class="col-12 mb-3">
                                            <label for="foto_perfil" class="form-label">Foto de Perfil</label>
                                            <input type="file" class="form-control" @change="handleFileUpload" />
                                            <div class="text-danger">{{ formErrors.foto_perfil }}</div>
                                            <div v-if="form.foto_perfil">
                                                <img :src="form.foto_perfil" alt="Foto de Perfil" width="100" />
                                            </div>
                                        </div>
                                        <!-- Campo Cliente 
                                        <div class="col-12 mb-3">
                                            <label for="clientes" class="form-label">Clientes</label>
                                            <v-select :options="clientes" label="nombres" :reduce="cliente => cliente.id" v-model="form.id_cliente" :close-on-select="false"></v-select>
                                            <div class="text-danger">{{ formErrors.id_cliente }}</div>
                                        </div>-->
                                        <!-- Campo Tipo de Usuario -->
                                        <div class="col-12 mb-3">
                                            <label for="tipo_cliente" class="form-label">Tipo de Usuario</label>
                                            <select name="tipo_cliente" class="form-control" id="tipo_cliente" v-model="form.tipo_cliente">
                                                <option value="Alumno">Alumno</option>
                                                <option value="Administrador">Administrador</option>
                                                <option value="Docente">Docente</option>
                                            </select>
                                            <div class="text-danger">{{ formErrors.tipo_cliente }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    
                    <!-- PIE DEL MODAL -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closeModal">Cerrar</button>
                        <button type="button" class="btn btn-primary" @click="guardarUsuario">Guardar</button>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>

<script>
import Preloader from "../components/Preloader.vue";
import Clienteservice from '../../services/Clienteservice';
import UsuarioService from '../../services/UsuarioService';
import axios from 'axios';
import $ from 'jquery';

export default {
    name: 'Usuarios',
    data() {
        return {
            usuarios: [],
            clientes: [],
            cargando: false,
            modalTitle: "",
            form: {
                nombres: "",
                apellidos: "",
                correo: "",
                numero_documento: "",
                telefono: "",
                direccion: "",
                foto_perfil: "",
                tipo_cliente: "Alumno",
                estado: 1,
                id_cliente: 4,
                ciudad: '',
                profesion: '',
                tipo_documento: '',
                codigo_alumno: '',
                password: '',
                password_confirmation: '',
                fecha_nacimiento:'',
            },
            formErrors: {},
            file: null,
            currentUsuarioId: null
        };
    },
    created() {
        this.fetchUsuarios();
        this.fetchClientes();
    },
    components: {
            Preloader,
        },
    methods: {
   
    onFileChange(event) {
            this.file = event.target.files[0];
        },
        // Método para enviar el archivo de importación al backend
        async uploadFile() {
            if (!this.file) return;
            
            const formData = new FormData();
            formData.append('file', this.file);
            try {
                const apiUrl = `${process.env.VUE_APP_API_URL}/api/import-usuarios`;
                const response = await axios.post(apiUrl, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                this.$swal({
                    icon: "success",
                    title: "Importación Completa",
                    text: response.data.message || "Los usuarios fueron importados correctamente."
                });
                location.reload();
                this.fetchUsuarios(); // Actualizar la lista de usuarios después de importar
            } catch (error) {
                console.error("Error al importar usuarios:", error);
                this.$swal({
                    icon: "error",
                    title: "Error de Importación",
                    text: error.response.data.message || "Ocurrió un error al importar el archivo."
                });
            }
        },
        nuevoItem() {
            this.modalTitle = "Nuevo Usuario";
            this.currentUsuarioId = null;
            this.form = {
                nombres: "",
                apellidos: "",
                correo: "",
                numero_documento: "",
                telefono: "",
                direccion: "",
                foto_perfil: "",
                tipo_cliente: "Alumno",
                estado: 1,
                id_cliente: '',
                password: '',
                password_confirmation: '',
                ciudad: '',
                profesion: '',
                tipo_documento: 'DNI',
                codigo_alumno: '',
                fecha_nacimiento:'',
            };
            this.showModal();
        },
        async fetchUsuarios() {
            try {
                this.cargando = true;
                const response = await UsuarioService.getUsuariosActivos();
                this.usuarios = response.data.data;
                this.$nextTick(() => {
                    if ($.fn.dataTable.isDataTable(this.$refs.itemtabla)) {
                        $(this.$refs.itemtabla).DataTable().destroy();
                    }
                    this.dataTable = $(this.$refs.itemtabla).DataTable({
                        order: [[0, 'desc']],
                        pageLength: 20
                    });
                });
            } catch (error) {
                console.error("Error fetching usuarios:", error);
                this.$swal({
                    icon: "error",
                    title: "Error",
                    text: "Hubo un error al cargar las usuarios.",
                });
            } finally {
                this.cargando = false;//OCULTA EL INDICADOR DE CARGA
            }
        },
        async fetchClientes() {
            try {
                this.cargando = true;
                const response = await Clienteservice.getClientesActivos();
                this.clientes = response.data.data;
            } catch (error) {
                console.error("Error fetching cliente:", error);
                this.$swal({
                    icon: "error",
                    title: "Error",
                    text: "Hubo un error al cargar los clientes.",
                });
            } finally {
                this.cargando = false;//OCULRA EL INDICADOR DE CARGA
            }
        },
        async guardarUsuario() {
            if (this.form.password !== this.form.password_confirmation) {
                this.$swal({
                    icon: "error",
                    title: "Error",
                    text: "Las contraseñas no coinciden",
                });
                return;
            }
            const formData = new FormData();
            // Asegúrate de agregar todos los campos al FormData, incluso si están vacíos
            formData.append("nombres", this.form.nombres || '');
            formData.append("apellidos", this.form.apellidos || '');
            formData.append("correo", this.form.correo || '');
            formData.append("numero_documento", this.form.numero_documento || '');
            formData.append("telefono", this.form.telefono || '');
            formData.append("direccion", this.form.direccion || '');
            formData.append("tipo_cliente", this.form.tipo_cliente || '');
            formData.append("estado", this.form.estado !== null ? this.form.estado : 1);
            formData.append("id_cliente",1);
            formData.append("password", this.form.password || '');
            formData.append("password_confirmation", this.form.password_confirmation || '');
            formData.append("ciudad", this.form.ciudad || '');
            formData.append("profesion", this.form.profesion || '   ');
            formData.append("tipo_documento", this.form.tipo_documento || '');
            formData.append("codigo_alumno", this.form.codigo_alumno || '');
            formData.append("fecha_nacimiento", this.form.fecha_nacimiento || '');
            // Manejar la imagen de perfil si existe
            if (this.file) {
                formData.append("foto_perfil", this.file);
            }
            try {
                if (this.currentUsuarioId) {
                    this.cargando = true;
                    console.log([...formData.entries()]); // Verifica que todos los datos están presentes antes de enviarlos.
                    formData.append("_method", "PUT"); // IMPORTANTE: Esto le indica a Laravel que es una actualización
                    // Actualizar usuario
                    await UsuarioService.updateUsuario(this.currentUsuarioId, formData);
                    this.$swal({
                        icon: "success",
                        title: "Usuario Actualizado",
                        text: "El usuario ha sido actualizado exitosamente.",
                    });
                } else {
                    // Crear usuario nuevo
                    this.cargando = true;
                    await UsuarioService.createUsuario(formData);
                    this.$swal({
                        icon: "success",
                        title: "Usuario Registrado",
                        text: "El usuario ha sido registrado exitosamente.",
                    });
                }
                location.reload();
                this.fetchUsuarios()
                this.closeModal();
            } catch (error) {
                if (error.response && error.response.data.errors) {
                    this.formErrors = error.response.data.errors;
                } else {
                    console.error("Error guardando Usuario:", error);
                    this.$swal({
                        icon: "error",
                        title: "Error",
                        text: "Hubo un error al guardar el usuario.",
                    });
                }
            }
            finally {
                this.cargando = false;//OCULRA EL INDICADOR DE CARGA
            }
        },
        handleFileUpload(event) {
            this.file = event.target.files[0];
        },
        showModal() {
            const modal = document.getElementById("principalModal");
            modal.classList.add("show");
            modal.style.display = "block";
            modal.setAttribute("aria-modal", "true");
            modal.removeAttribute("aria-hidden");
        },
        closeModal() {
            const modal = document.getElementById("principalModal");
            modal.classList.remove("show");
            modal.style.display = "none";
            modal.setAttribute("aria-hidden", "true");
            modal.removeAttribute("aria-modal");
            this.formErrors = [];
        },
        verUsuario(usuario) {
            const foto_perfilUrl = process.env.VUE_APP_API_URL + usuario.foto_perfil;
            const detalles = `
        <div style="display: flex; flex-wrap: wrap;">
            <div style="flex: 1; min-width: 50%; text-align: left; padding-right: 10px;">
                <p><strong>Nombre del alumno:</strong> ${usuario.nombres}</p>
                <p><strong>Apellidos:</strong> ${usuario.apellidos}</p>
                <p><strong>Correo:</strong> ${usuario.correo}</p>
                <p><strong>Número de documento:</strong> ${usuario.numero_documento}</p>
                <p><strong>Teléfono:</strong> ${usuario.telefono}</p>
                <p><strong>Dirección:</strong> ${usuario.direccion}</p>
                <p><strong>Ciudad:</strong> ${usuario.ciudad}</p>
                <p><strong>Foto perfilUrl:</strong> <img src="${foto_perfilUrl}" alt="${usuario.nombre}" width="100"></p>        </div>
                <div style="flex: 1; min-width: 50%; text-align: left; padding-left: 10px;">
                <p><strong>Profesión:</strong> ${usuario.profesion}</p>
                <p><strong>Tipo de documento:</strong> ${usuario.tipo_documento}</p>
                <p><strong>Código de alumno:</strong> ${usuario.codigo_alumno}</p>
                <p><strong>Tipo de cliente:</strong> ${usuario.tipo_cliente}</p>
                <p><strong>Estado:</strong> ${usuario.estado ? "Activo" : "Inactivo"}</p>
                <p><strong>Creado:</strong> ${usuario.created_at}</p>
                <p><strong>Actualizado:</strong> ${usuario.updated_at}</p>
            </div>
        </div>
        `;

            this.$swal({
                icon: "info",
                title: "Detalles del usuario",
                html: detalles,
                customClass: {
                    popup: "swal-wide",
                },
                width: "800px",  // Puedes ajustar el ancho para mejorar la presentación si es necesario
            });
        },

        editarUsuario(usuario) {
            // Construye la URL de la foto de perfil solo si existe
            const foto_perfilUrl = process.env.VUE_APP_API_URL + usuario.foto_perfil;
            this.modalTitle = "Editar Usuario";
            this.currentUsuarioId = usuario.id;
            // Establece los datos del formulario con los valores del usuario
            this.form = {
                nombres: usuario.nombres || "",
                apellidos: usuario.apellidos || "",
                correo: usuario.correo || "",
                numero_documento: usuario.numero_documento || "",
                fecha_nacimiento: usuario.fecha_nacimiento || "",
                telefono: usuario.telefono || "",
                direccion: usuario.direccion || "",
                foto_perfil: foto_perfilUrl, // Asignamos la URL de la foto de perfil al campo del formulario
                tipo_cliente: usuario.tipo_cliente || "Alumno", // Valor predeterminado: "Alumno"
                estado: usuario.estado !== undefined ? usuario.estado : 1, // Estado predeterminado: 1
                id_cliente: usuario.id_cliente || '', // ID del cliente o vacío si no está definido
                password: '', // Siempre vacío para seguridad
                password_confirmation: '', // Siempre vacío para seguridad
                ciudad: usuario.ciudad || '',
                profesion: usuario.profesion || '',
                tipo_documento: usuario.tipo_documento || 'DNI', // Valor predeterminado: "DNI"
                codigo_alumno: usuario.codigo_alumno || '',
            };
            // Muestra el modal de edición
            this.showModal();
        }
        ,
        async desactivarUsuario(id) {
            try {
                await UsuarioService.desactivarUsuario(id);
                this.$swal({
                    icon: "success",
                    title: "Usuario DesactivadO",
                    text: "El usuario ha sido desactivado",
                }).then(() => {
                    this.fetchUsuarios();
                });
            } catch (error) {
                console.error("Error desactivando el usuario:", error);
                this.$swal({
                    icon: "error",
                    title: "Error",
                    text: "Hubo un error al desactivar el usuario.",
                });
            }
            location.reload();
        },
    }
};
</script>
<style scoped>
.modal-dark {
    background-color: rgba(0, 0, 0, 0.3);
    /* Fondo más oscuro */
}

</style>