<template>
    <div class="card p-4">
        <div class="card">
            <div class="card-header">
                 <h6>Enviar Notificaciones</h6>
            </div>
        </div>
        <div class="card-body px-0 pt-0 pb-2">
            <form>
                
                <div class="row">
                    <div class="col-md-4">
                      <label for="tipo_pregunta" class="form-label">Tipo</label>
                        <div class="d-flex">
                          <div class="form-check me-3">
                              <input class="form-check-input" type="radio" id="tipo_destinatario"
                                  v-model="tipo_destinatario" value="alumno" />
                              <label class="form-check-label" for="tipo_destinatario">Estudiante</label>
                          </div>
                          <div class="form-check">
                              <input class="form-check-input" type="radio" id="tipo_destinatario"
                                  v-model="tipo_destinatario" value="curso" />
                              <label class="form-check-label" for="tipo_destinatario">Curso</label>
                          </div>
                      </div>
                    </div>  
                    <div class="col-md-4" v-show="tipo_destinatario=='alumno'">
                        <div class="mb-3">
                            <label for="alumno" class="form-label">Alumnos</label>
                            <v-select :options="alumnos" label="nombres_completos" :reduce="alumno => alumno.id" v-model="form.id_alumno" :close-on-select="true"></v-select>
                        </div>
                    </div>    
                    <div class="col-md-4" v-show="tipo_destinatario=='curso'">
                        <div class="mb-3">
                            <label for="curso" class="form-label">Cursos</label>
                            <v-select :options="cursos" label="nombre" :reduce="curso => curso.id" v-model="curso" :close-on-select="true"></v-select>
                        </div>
                    </div>  

                  </div>

  
                <!-- Mensaje -->
                <div class="mb-3">
                  <label for="mensaje" class="form-label">Mensaje</label>
                  <textarea
                    class="form-control"
                    id="mensaje"
                    v-model="form.mensaje"
                    placeholder="Escribe el mensaje aquí..."
                    rows="4"
                    required
                  ></textarea>
                  <div class="text-danger">{{ formErrors.mensaje }}</div>
                </div>

                <button type="button" class="btn btn-danger" @click="guardarNotificacion">Guardar</button>
                   
  
              </form>
        </div>
        <Preloader :visible="cargando" />
    </div>
    
  </template>
  
  <script>
  import "vue-select/dist/vue-select.css";
  import vSelect from "vue-select";
  import UsuarioService from "../../services/UsuarioService";
  import CursoService from "../../services/CursoService";
import NotificacionesService from "../../services/NotificacionesService";
import MatriculaService from "../../services/MatriculaService";
import Preloader from "../components/Preloader.vue";

  export default {
    name: "Notificaciones",
    components: {
      vSelect,
      Preloader
    },
    data() {
      return {
        cargando:false,
        tipo_destinatario:'',
        alumnos:[],
        cursos:[],
        curso:null,

        matriculas:[],


        usuarios: [], // Lista de usuarios disponibles
        form: {
          tipo: "Otro",
          fecha_envio:new Date().toISOString().split('T')[0],          estado:1,
          mensaje: "",
          id_alumno: null,
        },
        formErrors: {}, // Almacena errores del formulario
      
      };
    },
     
    mounted() {
      this.fetchAlumnos();
      this.fetchCursos();
    },
    methods: {
     async fetchAlumnos() {
            try {
                this.cargando = true;
                const response = await UsuarioService.getAlumnosActivos();
                this.alumnos = response.data.data;
               
            } catch (error) {
                console.error("Error fetching alumnos:", error);
                this.$swal({
                    icon: "error",
                    title: "Error",
                    text: "Hubo un error al cargar los alumnos.",
                });
            } finally {
                this.cargando = false;
            }
      },
      async fetchCursos() {
            try {
                const response = await CursoService.getCursos();
                this.cursos = response.data.data;
            } catch (error) {
                console.error("Error fetching cursos:", error);
            }
        },

        limpiarP(){
            this.tipo_destinatario=null
            
            this.form={
                tipo: "Otro",
                fecha_envio:new Date().toISOString().split('T')[0],         
                estado:1,
                mensaje: "",
                id_alumno: null,
                
            }
            this.$swal({
                            icon: "success",
                            title: "Enviada",
                            text: "La notificación ha sido enviada",
                        });
        },
      async guardarNotificacion() {

        try {
            if(this.tipo_destinatario=='alumno'){
                const response = await NotificacionesService.createNotificaciones(this.form);
                this.limpiarP();
                console.log(response); 
            }
            else if(this.tipo_destinatario=='curso'){
                this.llamarMatriculas() 
            }
            else{
                console.log('ninguno');
                
            }
                   
     
        }  catch (error) {
            console.error("notiificacion:", error);
        }
        finally {
            this.cargando = false; 
        }
        
      },
      async llamarMatriculas(){
        try {
                this.cargando = true;
                const response = await MatriculaService.getMatriculasPorCurso(this.curso);
                this.matriculas = response.data.data;
                for (const matricula of this.matriculas) {
                    this.form.id_alumno = matricula.id_usuario; 
                    try {
                        const notificacionResponse = await NotificacionesService.createNotificaciones(this.form);
                        this.limpiarP();
                        console.log(notificacionResponse);
                    } catch (notificacionError) {
                        console.error(`Error al enviar la notificación al alumno ${matricula.id_usuario}:`, notificacionError);
                    }
                }              
               
            } catch (error) {
                console.error("Error fetching matriculas:", error);
             } finally {
                this.cargando = false;
            }
        
      }
    },
  };
  </script>
  
  <style scoped>
  .modal-body {
    max-height: 500px;
    overflow-y: auto;
  }
  
  .text-danger {
    font-size: 0.875rem;
    color: #e3342f;
  }
  </style>
  