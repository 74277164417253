<template>
  <div class="card">
    <!-- Encabezado de la tarjeta con el título y el botón para crear una nueva categoría -->
    <div class="card-header pb-0 d-flex align-items-center justify-content-between">
      <h6>Categoria</h6>
      <Preloader :visible="cargando" />
      <button class="btn btn-primary ml-auto" @click="nuevoItem">NUEVO</button>
    </div>
    <!-- Cuerpo de la tarjeta con la tabla de categorías -->
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-4">
        <table class="table mb-0 class=table mb-0" >
          <thead>
            <tr>
              <th class="text-center text-secondary opacity-7">ID</th>
              <th class="text-start text-secondary opacity-7">Nombre</th>
              <th class="text-start text-secondary opacity-7">Descripción</th>
              <th class="text-center text-secondary opacity-7">Opciones</th>
            </tr>
          </thead>
          <!-- Cuerpo de la tabla que itera sobre la lista de categorías -->
          <tbody>
            <!-- Itera sobre las categorías y genera una fila para cada categoría -->
            <tr v-for="(categoria, index) in categorias" :key="index">
              <td class="text-center" >{{ index+1 }}</td>
              <td class="text-start" >{{ categoria.nombre }}</td>
              <td class="text-start" >{{ categoria.descripcion }}</td>
              <td class="text-center">
                <!-- Botón para ver los detalles de la categoría -->
                <button @click="verCategoria(categoria)" class="badge badge-sm bg-gradient-success">
                  <i class="far fa-eye" aria-hidden="true"></i>
                </button>
                <!-- Botón para editar la categoría -->
                <button
                  @click="editarCategoria(categoria)"
                  class="badge badge-sm bg-gradient-warning">
                  <i class="far fa-edit" aria-hidden="true"></i>
                </button>
                <!-- Botón para desactivar la categoría -->
                <button
                  @click="desactivarCategoria(categoria.id)"
                  class="badge badge-sm bg-gradient-danger">
                  <i class="far fa-trash-alt" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Modal para crear o editar una categoría -->
    <div
      class="modal fade"
      id="principalModal"
      tabindex="-1"
      aria-labelledby="principalModalLabel"
      aria-hidden="true">
      
      <div class="modal-dialog">
        <div class="modal-content">
          <!-- Cabecera del modal que muestra el título dinámico -->
          <div class="modal-header">
            <h5 class="modal-title" id="principalModalLabel">
              {{ modalTitle }}
            </h5>
            <!-- Botón para cerrar el modal -->
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closeModal"
            ></button>
          </div>
          <!-- Cuerpo del modal con el formulario para nombre, descripción e imagen -->
          <div class="modal-body">
            <form id="premioForm">
              <div class="mb-3">
                <label for="nombre" class="form-label">Nombre de Categoría</label>
                <input type="text" 
                  class="form-control" 
                  id="nombre" 
                  v-model="form.nombre"
                />
                <!-- Mensaje de error para el campo 'nombre' -->
                <div class="text-danger">{{ formErrors.nombre }}</div>
              </div>
              <div class="mb-3">
                <label for="descripcion" class="form-label">Descripción</label>
                <!-- Campo de texto para la descripción vinculado a 'form.descripcion' -->
                <textarea 
                  class="form-control" 
                  id="descripcion" 
                  v-model="form.descripcion"
                ></textarea>
                <div class="text-danger">{{ formErrors.descripcion }}</div>
              </div>

            <!-- Campo para subir imagen -->
              <div class="mb-3">
                <label for="imagen" class="form-label">Imagen</label>
                <input 
                  type="file" 
                  class="form-control" 
                  @change="handleFileUpload"
                />
                <div class="text-danger">{{ formErrors.imagen }}</div>
                <!-- Vista previa de la imagen seleccionada -->
                <div v-if="form.imagen">
                  <img :src="form.imagen" alt="Imagen de la categoria" width="100" />
                </div>
              </div>
            </form>
          </div>
          <!-- Pie del modal con los botones para cerrar y guardar la categoría -->
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click="closeModal"
            >
              Cerrar
            </button>
            <!-- Botón para guardar la categoría -->
            <button
              type="button"
              class="btn btn-primary"
              @click="guardarCategoria"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Preloader from "../components/Preloader.vue";  
import CategoriaService from "../../services/CategoriaService";// Importación del servicio de categorías

export default {
  name: "Categorias",
  data() {
    return {
      categorias: [],// Almacena la lista de categorías
      cargando: false,// Indicador de carga
      form: {
        nombre: "",// Nombre de la categoría
        imagen: "",// Imagen de la categoría
        descripcion: "",// Descripción de la categoría
        estado: 1,// Estado de la categoría (activa por defecto)
      },
      formErrors: {}, // Almacena errores de validación del formulari
      file: null,// Almacena el archivo de imagen subido
      modalTitle: "",// Títhulo dinámico del modal (crear/editar)
      currentCategoriaId: null,// Almacena el ID de la categoría actual (para editar)
    };
  },
  created() {
    this.fetchCategorias();// Llama a la función para cargar las categorías cuando el componente se monta
  },
  components: {
      Preloader
    },
  methods: {
    async fetchCategorias() {
       // Función para obtener las categorías activas desde el servidor
      try {
        this.cargando = true;
        const response = await CategoriaService.getCategoriasActivas();
        this.categorias = response.data.data; // Asigna las categorías recibidas
      } catch (error) {
        console.error("Error fetching categorias:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar las categorías.",
        });
      } finally {
        this.cargando = false; // Finaliza el estado de carga
      }
    },
    verCategoria(categoria) {
      // Función para mostrar los detalles de una categoría en un modal
      const imagenUrl = process.env.VUE_APP_API_URL + categoria.imagen; // Obtiene la URL de la imagen
      const detalles = `
        <div style="display: flex; flex-wrap: wrap;">
          <div style="flex: 1; min-width: 50%; text-align: left; padding-right: 10px;">
            <p><strong>Nombre:</strong> ${categoria.nombre}</p>
            <p><strong>Descripción:</strong> ${categoria.descripcion}</p>
            <p><strong>Imagen URL:</strong> <img src="${imagenUrl}" alt="${categoria.nombre}" width="100"></p>
          </div>
          <div style="flex: 1; min-width: 50%; text-align: left; padding-left: 10px;">
            <p><strong>Estado:</strong> ${categoria.estado ? "Activo" : "Inactivo"}</p>
          </div>
        </div>
      `;

      this.$swal({
        icon: "info",
        title: "Detalles de la categoría",
        html: detalles, // Muestra los detalles de la categoría en formato HTML
        customClass: {
          popup: "swal-wide",
        },
        width: "600px",// Ancho del modal de detalles
      });
    },

    editarCategoria(categoria) {
      // Función para abrir el modal de edición con los datos de una categoría existente
      const imagenUrl = process.env.VUE_APP_API_URL + categoria.imagen;
      this.modalTitle = "Editar Categoría";// Cambia el título del modal a "Editar"
      this.currentCategoriaId = categoria.id;// Asigna el ID de la categoría actual
      this.form = {
        nombre: categoria.nombre,// Carga los datos actuales de la categoría en el formulario
        descripcion: categoria.descripcion,
        imagen: imagenUrl,
        estado: categoria.estado,

      };
      this.showModal();// Muestra el modal
    },
    async desactivarCategoria(id) {
       // Función para desactivar una categoría
      try {
        await CategoriaService.desactivarCategoria(id);
        this.$swal({
          icon: "success",
          title: "Categoría Desactivada",
          text: "La categoría ha sido desactivada",
        }).then(() => {
          this.fetchCategorias();
        });
      } catch (error) {
        console.error("Error desactivando la categoría:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al desactivar la categoría.",
        });
      }
    },
    
    async guardarCategoria() {
      const formData = new FormData();
      formData.append("nombre", this.form.nombre);
      formData.append("descripcion", this.form.descripcion);
      if (this.file) {
        formData.append("imagen", this.file);
      }
      formData.append("estado", this.form.estado);

      try {
        if (this.currentCategoriaId) {
          // Si hay un ID, estamos editando una categoría existente
          formData.append("_method", "PUT");
          await CategoriaService.updateCategoria(
            this.currentCategoriaId,
            formData
          );
          this.$swal({
            icon: "success",
            title: "Categoría Actualizada",
            text: "La categoría ha sido actualizada exitosamente.",
          });
        } else {
          // Si no hay ID, estamos creando una nueva categoría
          await CategoriaService.createCategoria(formData);
          this.$swal({
            icon: "success",
            title: "Categoría Registrada",
            text: "La categoría ha sido registrada exitosamente.",
          });
        }
        this.fetchCategorias();// Actualiza la lista de categorías
        this.closeModal();// Cierra el modal
      } catch (error) {
        if (error.response && error.response.data.errors) {
          this.formErrors = error.response.data.errors;
        } else {
          console.error("Error guardando categoría:", error);
          this.$swal({
            icon: "error",
            title: "Error",
            text: "Hubo un error al guardar la categoría.",
          });
        }
      }
    },
    // Maneja la subida de archivos de imagen
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    // Prepara el formulario para crear una nueva categoría
    nuevoItem() {
      this.modalTitle = "Nueva Categoría";
      this.currentCategoriaId = null;
      this.form = {
        nombre: "",
        imagen: "",
        descripcion: "",
        estado: 1,
      };
      this.showModal();// Abre el modal
    },
    showModal() {
      // Muestra el modal manualmente
      const modal = document.getElementById("principalModal");
      modal.classList.add("show");
      modal.style.display = "block";
      modal.setAttribute("aria-modal", "true");
      modal.removeAttribute("aria-hidden");
    },
    closeModal() {
      // Cierra el modal manualmente
      const modal = document.getElementById("principalModal");
      modal.classList.remove("show");
      modal.style.display = "none";
      modal.setAttribute("aria-hidden", "true");
      modal.removeAttribute("aria-modal");
      this.formErrors = {};// Limpia los errores del formulario
    },
  },
};
</script>

<style>
/* Puedes agregar estilos adicionales aquí */
</style>