import apiClient from "../axios";

export default {
    getDetalledeEvaluacionporEvaluacion(id) {
        return apiClient.get(`/evaluaciones/${id}/detalles`);
    },
    updateDetalle(id, data) {
        return apiClient.put(`/detalle-evaluacion/${id}`, data, {
        });
    },  
};                                  