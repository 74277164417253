<template>
  <div class="card">
    <!-- Encabezado de la tarjeta -->
    <div class="card-header pb-0 d-flex align-items-center justify-content-between">
      <h6>Estudiantes</h6>
      <Preloader :visible="cargando" />
    </div>
    <!-- Cuerpo de la tarjeta con la tabla -->
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-4">
        <table class="table mb-0">
          <thead>
            <tr>
              <th class="text-center text-secondary opacity-7">ID</th>
              <th class="text-start text-secondary opacity-7">Nombre</th>
              <th class="text-center text-secondary opacity-7" >Nota</th>
              <th class="text-center text-secondary opacity-7">Intentos</th>
              <th class="text-center text-secondary opacity-7" >Estado</th>
              <th class="text-center text-secondary opacity-7">Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(detalle, index) in detalle_evaluaciones" :key="index">
              <td class="text-center">{{ index + 1 }}</td>
              <td class="text-start">{{ detalle.alumno }}</td>
              <td class="text-center">
                <span v-if="detalle.condicion">{{ detalle.nota }}</span>
                <span v-else class="text-danger">Sin Nota</span>
              </td>
              <td class="text-center">{{ detalle.intentos }}</td>
              <td class="text-center">
                <span 
                v-if="detalle.condicion == 1" 
                class="text-success p-2">
                Calificado
              </span>
              <span 
                v-else 
                class="text-danger p-2">
                Sin calificar
              </span>
              </td>
              <td class="text-start">
                <button 
                  class="bg bg-success text-white p-2"
                  @click="abrirModalRespuestas(detalle)"
                  >
                  Corregir
                </button>
               
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Modal para mostrar respuestas -->
    <div class="modal fade" id="principalModal" tabindex="-1" aria-labelledby="principalModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <!-- Encabezado del modal -->
          <div class="modal-header">
            <h5 class="modal-title" id="principalModalLabel">{{ modalTitle }}</h5>
            
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal"></button>
          </div>
          <!-- Cuerpo del modal -->
          <div class="modal-body">
            <p style="font-size: 10px;">Las respuestas del alumno y el valor de la pregunta no pueden ser modificadas. Cambie el valor de la pregunta solo para generar la calificación</p>
            <!-- Botón de descargar certificado -->
         
            <h6>Respuestas del Estudiante</h6>
            <div v-if="respuestas.length === 0" class="text-muted">No hay respuestas disponibles.</div>
            <div v-else>
              <table class="table table-responsive">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Pregunta</th>
                    <th>Respuesta</th>
                    <th>Valor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(respuesta,index) in respuestas" :key="index">
                    <td>{{ index+1 }}</td>
                    <td>{{ respuesta.pregunta.pregunta }}</td>
                    <td>{{ respuesta.respuesta }}</td> 
                    <td>  <input type="number" 
                      class="form-control" 
                      id="nombre" 
                      v-model="respuesta.pregunta.valor"
                    /></td>                    
                   
                    
                  </tr>
                </tbody>
              </table>
              <div>
                <p>Calificación</p>
                <input type="number" 
                class="form-control" 
                id="nombre" 
                v-model="calificacion" readonly
              />
              </div>
             
            </div>
          </div>
          <!-- Pie del modal -->
          <div class="modal-footer d-flex justify-content-between">
            <!-- Botones de acción -->
            <div>
              <button
                type="button"
                class="btn btn-secondary me-2"
                data-bs-dismiss="modal"
                @click="closeModal"
              >
                Cerrar
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="calificarDetalle"
              >
                Calificar
              </button>
            </div>
               <div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Preloader from "../components/Preloader.vue";
import RespuestaService from "../../services/RespuestaService"; // Servicio para las respuestas
import DetalleEvaluacionService from "../../services/DetalleEvaluacionService"; // Servicio para detalle de evaluaciones

export default {
  name: "Categorias",
  data() {
    return {
      detalle_evaluaciones: [], // Lista de estudiantes
      respuestas: [], // Respuestas del estudiante seleccionado
      certificado: null, // Datos del certificado seleccionado
      id_idevaluacion: this.$route.params.idevaluacion, // ID de la evaluación
      cargando: false, // Indicador de carga
      modalTitle: "", // Título dinámico del modal
      valorTotal:0,
      detalle:{},
      notafinal:0
    };
  },
  created() {
    this.fetchDetalleEvaluaciones();
  },
  components: {
    Preloader,
  },
  computed:{
    calificacion(){
      let notaAlumnos=0
      let arrayObtenido = this.respuestas.map( function (r) {
           notaAlumnos = parseInt(r.pregunta.valor) + parseInt(notaAlumnos);
          
            return notaAlumnos;
        });
        console.log(arrayObtenido);    
      if(notaAlumnos<=this.valorTotal){
        return notaAlumnos;
      }
      else{
        const mensaje= this.$swal({
          icon: "error",
          title: "Error",
          text: "La nota máxima es : "+this.valorTotal,
        });
        return mensaje
       
      }
      
    }
  },
  watch: {
  calificacion(newVal) {
    console.log(newVal);
    
    // Actualiza la variable notafinal cada vez que cambie la calificación
   this.detalle.nota = newVal;
  },
},
  methods: {
    // Cargar los estudiantes
    async fetchDetalleEvaluaciones() {
      try {
        this.cargando = true;
        const response = await DetalleEvaluacionService.getDetalledeEvaluacionporEvaluacion(this.id_idevaluacion);
        this.detalle_evaluaciones = response.data.data;
      } catch (error) {
        console.error("Error al cargar los estudiantes:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "No se pudieron cargar los estudiantes.",
        });
      } finally {
        this.cargando = false;
      }
    },
    // Abrir el modal con las respuestas de un estudiante
    async abrirModalRespuestas(detalle) {
      this.modalTitle = `Respuestas de: ${detalle.alumno}`;
      this.certificado = detalle; // Guarda los datos del certificado para descargar
      try {
        const response = await RespuestaService.getRespuestasPorDetalle(detalle.id);
        this.respuestas = response.data.data;
        this.detalle=detalle
        let total=0
        let obtener = this.respuestas.map( function (r) {
          total = parseInt(r.pregunta.valor) + parseInt(total);          
            return total;
        });
        console.log(total);
        this.valorTotal=total
        
        console.log(obtener);
        

        this.showModal();
        
      } catch (error) {
        console.error("Error al cargar respuestas:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "No se pudieron cargar las respuestas.",
        });       
      }
    
    },
    // Guardar cambios en todas las respuestas
    async calificarDetalle() {
      this.detalle.condicion=1
      try {
       
          await DetalleEvaluacionService.updateDetalle(this.detalle.id, this.detalle);
        
        this.$swal({
          icon: "success",
          title: "Calificado",
          text: "Las respuestas han sido calificadas correctamente.",
        });
        this.fetchDetalleEvaluaciones();
        this.closeModal();
      } catch (error) {
        console.error("Error al calificar las respuestas:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "No se pudieron calificar las respuestas.",
        });
      }
    },
    // Descargar certificado
    descargarCertificado(certificado) {
      const url = `https://certificado.micampusenlinea.com/certificadoacc.php?id=${certificado.id}`;
      window.open(url, "_blank");
    },
    // Mostrar el modal
    showModal() {
      const modal = document.getElementById("principalModal");
      modal.classList.add("show");
      modal.style.display = "block";
      modal.setAttribute("aria-modal", "true");
      modal.removeAttribute("aria-hidden");
    },
    // Cerrar el modal
    closeModal() {
      const modal = document.getElementById("principalModal");
      modal.classList.remove("show");
      modal.style.display = "none";
      modal.setAttribute("aria-hidden", "true");
      this.respuestas = [];
      this.certificado = null;
    },
  },
};
</script>

<style>
/* Hacer que el contenido del modal sea responsivo */
.modal-body {
  max-height: 70vh;
  overflow-y: auto;
}
.table-responsive {
  overflow-x: auto;
}
textarea {
  resize: vertical;
}
</style>
