<template>
    <div class="card">
        <div class="card-header">
            <h6>Certificados</h6>
            <Preloader :visible="cargando"/>
            <!-- Importacion masiva certificados-->
            <div class="m-4 p-4 border rounded shadow-sm bg-light"> <h3 class="text-primary">Importación Masiva de Certificados</h3> <p class="text-muted">Selecciona un archivo Excel para importar los certificados.</p> <div class="mb-3"> <input class="form-control" type="file" @change="onFileChange" /> </div> <button class="btn btn-primary" @click="uploadFile" :disabled="!file"> <i class="bi bi-upload"></i> Subir Archivo Excel de Certificados </button>
            <a href="https://app.micampusenlinea.com/template/plantillas.rar" target="_blank">Descargar Plantilla</a>
        </div>
            <button class="btn btn-primary ml-auto" @click="nuevoItem()">NUEVO</button>
        </div>
        <div class="card-body px-0 pt-0 pb-2">
            <div class="table-responsive p-4">
                <table class="table mb-0" ref="itemtabla">
                    <thead>
                        <tr class="text-uppercase">
                            <th class="text-center px-3 py-2">ID</th>
                            <th class="text-start px-3 py-2">Alumno</th>
                            <th class="text-start px-3 py-2">Descripción</th>
                            <th class="text-center px-3 py-2">Fecha emisión</th>
                            <th class="text-center px-3 py-2">Nota</th>
                            <th class="text-center px-3 py-2">Horas</th>
                            <th class="text-center px-3 py-2">Opciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(certificado, index) in certificados" :key="certificado.id">
                            <td class="text-center">{{ index + 1 }}</td>
                            <td class="text-start">{{ certificado . alumno }}</td> <!-- Mostrar el nombre del alumno -->
                            <td class="text-start">{{ certificado . curso }} 
                                {{ certificado . modulo ? certificado . modulo : ''}}</td>
                            <!-- Mostrar Curso y Módulo, si existe -->
                            <td class="text-center">{{ certificado . fecha_emision }}</td>
                            <td class="text-center">{{ certificado . nota }}</td>
                            <td class="text-center">{{ certificado . horas }}</td>
                            <td class="text-center">
                                <button @click="descargarCertificado(certificado)"
                                    class="badge badge-sm bg-gradient-info">
                                    <i class="fas fa-download" aria-hidden="true"></i> Descargar
                                </button>
                                <button @click="verCertificado(certificado)" class="badge badge-sm bg-gradient-success">
                                    <i class="far fa-eye" aria-hidden="true"></i>
                                </button>
                                <button @click="editarCertificado(certificado)"
                                    class="badge badge-sm bg-gradient-warning">
                                    <i class="far fa-edit" aria-hidden="true"></i>
                                </button>
                                <button @click="desactivarCertificados(certificado.id)"
                                    class="badge badge-sm bg-gradient-danger">
                                    <i class="far fa-trash-alt" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- Modal para crear o editar un certificado -->
        <div v-if="isModalVisible" class="modal" tabindex="-1" aria-labelledby="principalModalLabel"
            aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="principalModalLabel">{{ modalTitle }}</h5>
                        <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form @submit.prevent="saveItem">
                            <!-- Usamos un contenedor de grid con filas y columnas -->
                            <div class="row g-3"> <!-- g-3 para un espaciado uniforme entre las columnas -->
                                <!-- Tipo de Certificado -->
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label class="form-label">Tipo de Certificado</label>
                                        <div class="d-flex">
                                            <div class="form-check me-3">
                                                <input class="form-check-input" type="radio" id="tipo_curso"
                                                    v-model="form.tipo_certificado" value="curso"
                                                    @change="onTipoCertificadoChange" />
                                                <label class="form-check-label" for="tipo_curso">Curso</label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" id="tipo_modulo"
                                                    v-model="form.tipo_certificado" value="modulo"
                                                    @change="onTipoCertificadoChange" />
                                                <label class="form-check-label" for="tipo_modulo">Módulo</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Selección de Curso -->
                                <div class="col-md-12">
                                    <div class="mb-3">
                                        <label for="curso" class="form-label">Selecciona un Curso</label>
                                        <select id="curso" class="form-select" v-model="form.selectedCurso"
                                            @change="fetchModulosByCurso" required>
                                            <option v-for="curso in cursos" :key="curso.id"
                                                :value="curso.id">{{ curso . nombre }}</option>
                                        </select>
                                    </div>
                                </div>
                                <!-- Selección de Módulo (solo cuando tipo_certificado es 'modulo') -->
                                <div class="col-md-12" v-if="form.tipo_certificado === 'modulo'">
                                    <div class="mb-3">
                                        <label for="modulo" class="form-label">Selecciona un Módulo</label>
                                        <select id="modulo" class="form-select" v-model="form.selectedModulo"
                                            @change="cargarDatosM" required>
                                            <option v-for="modulo in modulos" :key="modulo.id"
                                                :value="modulo.id">{{ modulo . nombre }}</option>
                                        </select>
                                    </div>
                                </div>
                                <!-- Alumno -->
                                <div class="col-md-12">
                                    <div class="mb-3">
                                        <label for="alumno" class="form-label">Alumno</label>
                                        <v-select :options="alumnos" label="nombres_completos"
                                            :reduce="alumno => alumno.id" v-model="form.id_usuario"
                                            :close-on-select="true" required></v-select>
                                    </div>
                                </div>
                                <!-- Nota -->
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="nota" class="form-label">Nota</label>
                                        <input id="nota" v-model="form.nota" class="form-control"
                                            type="number" min="0" max="10" step="0.1" required />
                                    </div>
                                </div>
                                <!-- Horas -->
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="horas" class="form-label">Horas</label>
                                        <input id="horas" v-model="form.horas" class="form-control"
                                            type="number" min="1" required />
                                    </div>
                                </div>
                                <!-- Fecha de Emisión -->
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="fecha_emision" class="form-label">Fecha de Emisión</label>
                                        <input id="fecha_emision" v-model="form.fecha_emision" class="form-control"
                                            type="date" required />
                                    </div>
                                </div>
                                <!-- Fecha de Inicio -->
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="fecha_inicio" class="form-label">Fecha de Inicio</label>
                                        <input id="fecha_inicio" v-model="form.fecha_inicio" class="form-control"
                                            type="date" required />
                                    </div>
                                </div>
                                <!-- Fecha de Fin -->
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="fecha_fin" class="form-label">Fecha de Fin</label>
                                        <input id="fecha_fin" v-model="form.fecha_fin" class="form-control"
                                            type="date" required />
                                    </div>
                                </div>
                                <!-- Código de Certificado -->
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="codigo_certificado" class="form-label">Código de
                                            Certificado</label>
                                        <input id="codigo_certificado" v-model="form.codigo_certificado"
                                            class="form-control" type="text" required />
                                    </div>
                                </div>
                            </div> <!-- Fin de la fila (row) -->
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="closeModal">Cerrar</button>
                        <button type="button" class="btn btn-primary" @click="guardarCertificado">Guardar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Preloader from "../components/Preloader.vue";
    import CertificadoService from "../../services/CertificadoService";
    import CursoService from "../../services/CursoService";
    import ModuloService from "../../services/ModuloService";
    import UsuarioService from '../../services/UsuarioService';
    import axios from 'axios';
    import $ from 'jquery';

    export default {
        name: "Certificados",
        components: {
            Preloader
        },
        data() {
            return {
                certificados: [],
                cursos: [],
                modulos: [],
                usuarios: [],
                alumnos: [],
                cargando: false,
                form: {
                    id_usuario: null, // Valor por defecto
                    estado: 1, // Valor por defecto
                    tipo_certificado: '',
                    selectedCurso: null,
                    selectedModulo: null,
                    nota: 0, // Cambiado a string para asegurar la validación
                    horas: null,
                    fecha_emision: '',
                    fecha_inicio: '',
                    fecha_fin: '',
                    codigo_certificado: '', // Asegúrate de que esté vacío al inicio
                },
                modalTitle: 'Nuevo Certificado',
                currentItemId: null,
                isModalVisible: false, 
                file: null,// Nuevo estado para controlar la visibilidad del modal
            };
        },
        created() {
            this.fetchCertificados();
            this.fetchUsuarios();
            this.fetchCursos();
        },
        mounted() {
            this.fetchAlumnos();
        },
        methods: {
            onFileChange(event) {
            this.file = event.target.files[0];
        },
            async fetchUsuarios() {
                try {
                    this.cargando = true;
                    const response = await UsuarioService.getUsuariosActivos();
                    this.usuarios = response.data.data;
                   
                    
                } catch (error) {
                    console.error("Error fetching usuarios:", error);
                    this.$swal({
                        icon: "error",
                        title: "Error",
                        text: "Hubo un error al cargar los usuarios.",
                    });
                } finally {
                    this.cargando = false; // OCULTA EL INDICADOR DE CARGA
                }
            },
            async fetchCertificados() {
                try {
                    this.cargando = true;
                    const response = await CertificadoService.getCertificadosActivos();
                    this.certificados = response.data.data;
                    this.$nextTick(() => {
                        if ($.fn.dataTable.isDataTable(this.$refs.itemtabla)) {
                            $(this.$refs.itemtabla).DataTable().destroy();
                        }
                        this.dataTable = $(this.$refs.itemtabla).DataTable({
                            order: [
                                [0, 'desc']
                            ],
                            pageLength: 20
                        });
                    });
                } catch (error) {
                    console.error("Error fetching certificados:", error);
                } finally {
                    this.cargando = false;
                }
            },
            async guardarCertificado() {
            console.log('Curso seleccionado:', this.form.selectedCurso);
            console.log('Módulo seleccionado:', this.form.selectedModulo);
            if (this.form.selectedModulo != null) {
                this.form.id_modulo = this.form.selectedModulo;
            } else if (this.form.selectedCurso != null && this.form.selectedModulo == null) {
                this.form.id_curso = this.form.selectedCurso;
            }
            try {
                if (this.currentItemId) {
                    await CertificadoService.updateCertificado(this.currentItemId,this.form);
                    this.$swal({
                        icon: "success",
                        title: "Certificado Actualizado",
                        text: "El certificado ha sido actualizado exitosamente.",
                    });
                } else {
                    await CertificadoService.createCertificado(this.form);
                    this.$swal({
                        icon: "success",
                        title: "Certificado Registrado",
                        text: "El certificado ha sido registrado exitosamente.",
                    });
                    this.closeModal();
                    location.reload();
                }
               
                } catch (error) {
                    if (error.response && error.response.data.errors) {
                        // Mostrar errores específicos de cada campo
                        this.formErrors = error.response.data.errors;
                        console.error("Errores en la validación de campos:", this.formErrors);
                        this.$swal({
                            icon: "error",
                            title: "Error en los campos",
                            text: "Por favor, revisa los errores en los campos y vuelve a intentarlo.",
                        });
                    } else {
                        console.error("Error guardando certificado:", error);
                        this.$swal({
                            icon: "error",
                            title: "Error inesperado",
                            text: "Hubo un error al guardar el certificado.",
                        });
                            }
                            }
                        },
                    async fetchAlumnos() {
                        try {
                            this.cargando = true;
                            const response = await UsuarioService.getAlumnosActivos();
                            this.alumnos = response.data.data;
                        } catch (error) {
                            console.error("Error fetching alumnos:", error);
                            this.$swal({
                                icon: "error",
                                title: "Error",
                                text: "Hubo un error al cargar los alumnos.",
                            });
                        } finally {
                            this.cargando = false;
                        }
                    },

                    async fetchCursos() {
                        try {
                            const response = await CursoService.getCursos();
                            this.cursos = response.data.data;
                        } catch (error) {
                            console.error("Error fetching cursos:", error);
                        }
                    },

                    // Método para manejar el cambio del tipo de certificado (curso/modulo)
                    onTipoCertificadoChange() {
                        if (this.form.tipo_certificado === 'curso') {
                            this.form.selectedModulo = null; // Asegura que no se guarde el módulo
                            this.form.selectedCurso = null; // Asegura que se guarde el curso
                            this.modulos = []; // Limpiar los módulos

                            console.log('Tipo de certificado:', this.form.tipo_certificado);
                            console.log('Curso seleccionado:', this.form.selectedCurso);
                            console.log('Módulo seleccionado:', this.form.selectedModulo);
                        }

                        if (this.form.tipo_certificado === 'modulo') {
                            this.form.selectedCurso = null; // Asegura que no se guarde el curso
                            this.form.selectedModulo = null; // Asegura que se guarde el módulo
                        }
                    },

                    async fetchModulosByCurso() {
                        if (this.form.tipo_certificado === 'curso') {
                            try {
                                const response = await CursoService.getCurso(this.form.selectedCurso);
                                const cursosDetalle = response.data.data;
                                this.form.horas = cursosDetalle.horas; // Asigna las horas del curso
                                this.form.fecha_inicio = cursosDetalle.fecha_inicio;
                                this.form.fecha_fin = cursosDetalle.fecha_fin;
                            } catch (error) {
                                console.error("Error fetching curso:", error);
                            }
                        } else if (this.form.tipo_certificado === 'modulo') {
                            if (this.form.selectedCurso) {
                                try {
                                    const response = await ModuloService.getModulosPorCurso(this.form.selectedCurso);
                                    this.modulos = response.data.data;
                                    console.log("Módulos obtenidos:", this.modulos);
                                } catch (error) {
                                    console.error("Error fetching modulos:", error);
                                }
                            }
                        }
                    },
                    cargarDatosM() {
                        console.log("Módulo seleccionado:", this.form.selectedModulo);
                        if (this.form.selectedModulo) {
                            const selectedModulo = this.modulos.find(modulo => modulo.id === this.form.selectedModulo);
                            if (selectedModulo) {
                                this.form.horas = selectedModulo.horas;
                                this.form.fecha_inicio = selectedModulo.fecha_inicio;
                                this.form.fecha_fin = selectedModulo.fecha_fin;
                            }
                        }
                    },
                    limpiarD() {
                        this.form = {
                            fecha_inicio: '',
                            fecha_fin: '',
                            horas: 0
                        };
                    },
                    // Método para enviar el archivo de importación al backend
                    nuevoItem() {
                        this.modalTitle = "Crear nuevo Certificado";
                        this.currentItemId = null;
                        this.form = {
                            tipo_certificado: null,
                            selectedCurso: null,
                            selectedModulo: null,
                            nota: '0',
                            horas: '0',
                            id_usuario: null,
                            estado: 1,
                            fecha_emision: '',
                            fecha_inicio: '',
                            fecha_fin: '',
                            codigo_certificado: '', // Asegúrate de que esté vacío al iniciar
                        };
                        this.isModalVisible = true;
                    },
                    descargarCertificado(certificado) {
                        const url = `http://certificado.micampusenlinea.com/certificadoacc.php?id=${certificado.id}`;
                        window.open(url, '_blank');
                    },

                    verCertificado(certificado) {
                        const detalles = `
                    <div style="display: flex; flex-wrap: wrap;">
                    <div style="flex: 1; min-width: 50%; text-align: left; padding-right: 10px;">
                        <p><strong>Alumno:</strong> ${certificado.alumno}</p>
                        <p><strong>Curso:</strong> ${certificado.curso}</p>
                        <p><strong>Módulo:</strong> ${certificado.modulo ? certificado.modulo : ''}</p>
                        <p><strong>Nota:</strong> ${certificado.nota}</p>
                        <p><strong>Horas:</strong> ${certificado.horas}</p>
                        <p><strong>Código de Certificado:</strong> ${certificado.codigo_certificado}</p>
                        <p><strong>Fecha de Emisión:</strong> ${certificado.fecha_emision}</p>
                    </div>
                    <div style="flex: 1; min-width: 50%; text-align: left; padding-left: 10px;">
                        <p><strong>Fecha de Inicio:</strong> ${certificado.fecha_inicio}</p>
                        <p><strong>Fecha de Fin:</strong> ${certificado.fecha_fin}</p>
                        <p><strong>Estado:</strong> ${certificado.estado ? "Activo" : "Inactivo"}</p>
                        <p><strong>Certificado Lado A:</strong> ${certificado.certificado_lado_a}</p>
                        <p><strong>Certificado Lado B:</strong> ${certificado.certificado_lado_b}</p>
                    </div>
                    </div>
                    `;

                        this.$swal({
                            icon: "info",
                            title: "Detalles del Certificado",
                            html: detalles,
                            customClass: {
                                popup: "swal-wide",
                            },
                            width: "800px",
                        });
                    },
                    async desactivarCertificados(id) {
                    try {
                        await CertificadoService.desactivarCertificados(id);
                        this.$swal({
                            icon: "success",
                            title: "Certificado desactivado con éxito",
                        }).then(() => {
                            location.reload();
                        });
                    } catch (error) {
                        console.error("Error al desactivar el certificado:", error);
                        this.$swal({
                            icon: "error",
                            title: "Error al desactivar",
                            text: "Hubo un error al intentar desactivar la lección."
                        });
                    }
                },

                    editarCertificado(certificado) {
                        this.modalTitle = "Editar Certificado";
                        this.currentItemId = certificado.id;
                        this.form = {
                            tipo_certificado: certificado.id_modulo ? 'modulo' : 'curso',
                            selectedCurso: certificado.id_curso,
                            selectedModulo: certificado.id_modulo, // Si hay id_modulo, seleccionamos el módulo
                            id_usuario: certificado.id_usuario,
                            estado: certificado.estado,
                            nota: certificado.nota,
                            horas: certificado.horas,
                            fecha_emision: certificado.fecha_emision,
                            fecha_inicio: certificado.fecha_inicio,
                            fecha_fin: certificado.fecha_fin,
                            codigo_certificado: certificado.codigo_certificado || '',
                            certificado_lado_a: certificado.certificado_lado_a || '-',
                            certificado_lado_b: certificado.certificado_lado_b || '-',
                        };

                // Si el tipo de certificado es módulo, cargamos los módulos correspondientes
                if (this.form.tipo_certificado === 'modulo') {
                    this.fetchModulosByCurso().then(() => {
                        const moduloEncontrado = this.modulos.find(modulo => modulo.id === certificado
                            .id_modulo);
                        if (moduloEncontrado) {
                            this.form.selectedModulo = moduloEncontrado.id;
                            this.cargarDatosM();
                        }
                    });
                }

                this.isModalVisible = true;
            },

            closeModal() {
                this.isModalVisible = false;
            },

            //importacion masiva certificado
            async uploadFile() {
            if (!this.file) return;
            
            const formData = new FormData();
            formData.append('file', this.file);
            try {
                const apiUrl = `${process.env.VUE_APP_API_URL}/api/import-certificados`;
                const response = await axios.post(apiUrl, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                this.$swal({
                    icon: "success",
                    title: "Importación Completa",
                    text: response.data.message || "Los certificados fueron importados correctamente."
                });
               // location.reload();
               //  // Actualizar la lista de usuarios después de importar
            } catch (error) {
                console.error("Error al importar certificados:", error);
                this.$swal({
                    icon: "error",
                    title: "Error de Importación",
                    text: error.response.data.message || "Ocurrió un error al importar el archivo."
                });
            }
        },

            saveItem() {
                // Validar si el campo 'codigo_certificado' está vacío
                if (!this.form.codigo_certificado || this.form.codigo_certificado.trim() === "") {
                    this.$swal({
                        icon: "error",
                        title: "Error",
                        text: "El campo 'Código de Certificado' es obligatorio.",
                    });
                    return;
                }
                // Si el tipo de certificado es curso, poner id_modulo a null
                if (this.form.tipo_certificado === 'curso') {
                    this.form.selectedModulo = null; // Asegura que el módulo sea null
                }
                // Validar si 'nota' es un número entero
                const nota = parseFloat(this.form.nota);
                if (isNaN(nota) || !Number.isInteger(nota)) {
                    this.$swal({
                        icon: "error",
                        title: "Error",
                        text: "La nota debe ser un número entero.",
                    });
                    return;
                }
                
                // Si el tipo de certificado es módulo, poner id_curso a null
                if (this.form.tipo_certificado === 'modulo') {
                    this.form.selectedCurso = null; // Asegura que el curso sea null
                }
                // Crear un nuevo objeto para enviar los datos al backend
                const datosParaEnviar = {
                    ...this.form, // Incluye todos los campos del formulario
                    nota: parseInt(this.form.nota, 10), // Convierte la nota a número entero
                };
                
                // Validar si la nota es un número entero
                if (!this.validateNota()) {
                    return; // Detener si la validación de la nota falla
                }
                // Enviar los datos al backend
                this.cargando = true;
                const request = this.currentItemId ?
                    CertificadoService.updateCertificado(this.currentItemId, datosParaEnviar) :
                    CertificadoService.createCertificado(datosParaEnviar);
                request
                    .then(() => {
                        this.closeModal();
                        this.fetchCertificados(); // Recargar la lista de certificados
                    })
                    .catch((error) => {
                        console.error("Error al guardar el certificado:", error);
                        this.$swal({
                            icon: "error",
                            title: "Error",
                            text: "Hubo un error al guardar el certificado.",
                        });
                    })
                    .finally(() => {
                        this.cargando = false;
                    });
            }
        },
    };
</script>
<style scoped>
    .modal {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        justify-content: center;
        align-items: center;
    }

    .modal-dialog {
        max-width: 500px;
        margin: auto;
    }

    .modal-content {
        background: white;
        border-radius: 8px;
        padding: 20px;
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
</style>
