<template>
    <div class="card">
        <div class="card-header pb-0">
            <h6>Matriculas</h6>
            <div class="m-4">
                <p>Importación masiva de matrículas</p>
                <input class="form-control" type="file" @change="onFileChange" />
                <button class="btn btn-primary" @click="uploadFile" :disabled="!file">Subir archivo Excel de Matrículas</button>
            </div>
            <button class="btn btn-primary ml-auto" @click="nuevoItem">Nuevo</button>
        </div>
        <div class="card-body p-4">
            <div class="table-responsive p-0">
                <table class="table mb-0" ref="itemtabla">
                    <thead>
                        <tr>
                            <th class="text-secondary opacity-7">ID</th>
                            <th class="text-secondary opacity-7">Código</th>
                            <th class="text-secondary opacity-7">Alumnos</th>
                            <th class="text-secondary opacity-7">Curso</th>
                            <th class="text-center text-secondary opacity-7">Fecha de Matricula</th>
                            <th class="text-center text-secondary opacity-7">Fecha de Finalización</th>
                            <th class="text-center text-secondary opacity-7">Monto</th>
                            <th class="text-center text-secondary opacity-7">Estado</th>
                            <th class="text-center text-secondary opacity-7">Opciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(matricula, index) in matriculas" :key="index">
                            <td>{{ index+1 }}</td>
                            <td>{{ matricula.codigo_matricula }}</td> 
                            <td>{{ matricula.usuario.nombres }} {{ matricula.usuario.apellidos }}</td> 
                            <td>{{ matricula.curso.nombre }}</td> 
                            <td class="text-center">{{ matricula.fecha_inscripcion }}</td>
                            <td class="text-center">
                                {{ matricula.fecha_vencimiento ? matricula.fecha_vencimiento : "Sin fecha de vencimiento" }}
                            </td>
                            <td class="text-center">{{ matricula.monto }}</td>
                            <td class="text-center">
                                <template v-if="matricula.estado=='Activo'"><span class="text-success">Activa</span></template>
                                <template v-else><span class="text-danger">Suspendida</span></template>
                                
                            </td>
                            <td class="text-center">
                                <button @click="editarMatricula(matricula)" class="badge badge-sm bg-gradient-warning">
                                    <i class="far fa-edit" aria-hidden="true"></i>
                                </button>
                                <button @click="desactivarMatricula(matricula.id)"
                                    class="badge badge-sm bg-gradient-danger">
                                    <i class="far fa-trash-alt" aria-hidden="true"></i>
                                </button>
                                <button @click="SuspenderMatricula(matricula.id,matricula.estado)"
                                    class="badge badge-sm bg-gradient-primary">
                                    <i class="fas fa-lock" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        
        <div class="modal fade" id="principalModal" tabindex="-1" aria-labelledby="principalModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="principalModalLabel">{{ modalTitle }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal"></button>
                    </div>
                    <div class="modal-body">
                        <form id="premioForm">
                            <div class="row">                              
                                <div class="col-md-6">
                                    <label for="fecha_inscripcion" class="form-label">fecha de inscripcion</label>
                                    <input type="date" class="form-control" id="fecha_inscripcion" v-model="form.fecha_inscripcion" />
                                    <div class="text-danger">{{ formErrors.fecha_inscripcion }}</div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="fecha_vencimiento" class="form-label">Fecha de Finalización</label>
                                <input type="date" class="form-control" id="fecha_vencimiento" v-model="form.fecha_vencimiento" />
                                <div class="text-danger">{{ formErrors.fecha_vencimiento }}</div>
                            </div>
                            <div class="mb-3">
                                <label for="alumno" class="form-label">Alumno</label>
                                <v-select :options="alumnos" label="nombres_completos" :reduce="alumno => alumno.id" v-model="form.id_usuario" :close-on-select="true"></v-select>
                            </div>
                            <div class="mb-3 col-md-6">
                                <label for="monto" class="form-label">Monto</label>
                                <input type="number" class="form-control" id="monto" v-model="form.monto" />
                                <div class="text-danger">{{ formErrors.monto }}</div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closeModal">Cerrar</button>
                        <button type="button" class="btn btn-primary" @click="guardarMatricula">Guardar</button>
                    </div>
                </div>
            </div>
        </div> <!-- Cierre del modal -->
        <Preloader :visible="cargando" />
    </div>
</template>

<script>
import Preloader from "../components/Preloader.vue";
import MatriculaService from "../../services/MatriculaService";
import "vue-select/dist/vue-select.css";
import UsuarioService from "../../services/UsuarioService";
import axios from "axios";
import $ from 'jquery';

export default {
    name: "Matriculas",
    data() {
        return {
            matriculas: [],
            alumnos:[],
            cargando: false,
            modalTitle: "",
            idcurso: this.$route.params.id_curso, 
            currentMatriculaId: null,
            form: {
                fecha_inscripcion: '',
                fecha_vencimiento: null,
                estado: 1,
                id_usuario: '',
                id_registrador: 11,//Cambiar por usuario logueado
                id_curso: '',
                codigo_matricula: '',
                monto: '',
            },
            formErrors: {},
             file: null,
        };
    },
    components: {
            Preloader,
            
        },
    methods: {
        obtenerFechaActual() {
            const fecha = new Date();
            const anio = fecha.getFullYear();
            const mes = String(fecha.getMonth() + 1).padStart(2, '0'); // Meses de 0 a 11, así que sumamos 1
            const dia = String(fecha.getDate()).padStart(2, '0');
            return `${anio}-${mes}-${dia}`; // Formato YYYY-MM-DD
        },
         onFileChange(event) {
            this.file = event.target.files[0];
        },
        async uploadFile() {
            if (!this.file) return;
            const formData = new FormData();
            formData.append("file", this.file);
            try {
                this.cargando = true;
                const response = await axios.post('http://127.0.0.1:8000/api/import-matriculas', formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                });
                this.$swal({
                    icon: "success",
                    title: "Importación Completa",
                    text: response.data.message || "Las matrículas fueron importadas correctamente.",
                });
                this.fetchMatriculas();
            } catch (error) {
                console.error("Error al importar matrículas:", error);
                this.$swal({
                    icon: "error",
                    title: "Error de Importación",
                    text: error.response?.data?.message || "Ocurrió un error al importar el archivo.",
                });
            } finally {
                this.cargando = false;
                this.file = null;
            }
        },
        async fetchMatriculas() {
            try {
                this.cargando = true;
                const response = await MatriculaService.getMatriculasPorCurso(this.idcurso);
                this.matriculas = response.data.data;
                this.$nextTick(() => {
                        if ($.fn.dataTable.isDataTable(this.$refs.itemtabla)) {
                            $(this.$refs.itemtabla).DataTable().destroy();
                        }
                        this.dataTable = $(this.$refs.itemtabla).DataTable({
                            order: [
                                [0, 'desc']
                            ],
                            pageLength: 20 // Mostrar 20 filas por página
                        });
                    });
               
            } catch (error) {
                console.error("Error fetching matriculas:", error);
                this.$swal({
                    icon: "error",
                    title: "Error",
                    text: "Hubo un error al cargar las matrículas.",
                });
            } finally {
                this.cargando = false;
            }
        },
        async fetchAlumnos() {
            try {
                this.cargando = true;
                const response = await UsuarioService.getAlumnosActivos();
                this.alumnos = response.data.data;
               
            } catch (error) {
                console.error("Error fetching alumnos:", error);
                this.$swal({
                    icon: "error",
                    title: "Error",
                    text: "Hubo un error al cargar los alumnos.",
                });
            } finally {
                this.cargando = false;
            }
        },
        nuevoItem() {
            this.modalTitle = "Nueva Matricula";
            this.currentMatriculaId = null;
            this.form = {
                fecha_inscripcion: this.obtenerFechaActual(),
                fecha_vencimiento: null,
                estado: 1,
                id_usuario: '',
                id_registrador: 11,
                id_curso: this.idcurso,
                codigo_matricula:'',
                monto: '',
            };
            this.showModal();
           
        },
        async guardarMatricula() {
                try {
                    if (this.currentMatriculaId) {                       
                        await MatriculaService.updateMatricula(
                            this.currentMatriculaId,
                            this.form
                        );
                        this.$swal({
                            icon: "success",
                            title: "Matricula Actualizada",
                            text: "La Matrícula ha sido actualizada exitosamente.",
                        });
                    } else {
                        this.form.codigo_matricula='M'+this.form.id_usuario+'-'+this.form.id_curso
                        await MatriculaService.createMatricula(this.form);
                        this.$swal({
                            icon: "success",
                            title: "Matricula Registrada",
                            text: "La Matricula ha sido registrada exitosamente.",
                        });
                        location.reload();
                    }
                    this.fetchMatriculas();
                    this.closeModal();
                } catch (error) {
                    if (error.response && error.response.data.errors) {
                        this.formErrors = error.response.data.errors;
                    } else {
                        console.error("Error al guardar Matricula:", error);
                        this.$swal({
                            icon: "error",
                            title: "Error",
                            text: "Hubo un error al guardar la matricula.",
                        });
                    }
                }
            },
        editarMatricula(matricula) {
            this.modalTitle = "Editar Matricula";
            this.currentMatriculaId = matricula.id;
            this.form = {
                fecha_inscripcion: matricula.fecha_inscripcion,
                fecha_vencimiento: matricula.fecha_vencimiento,
                estado: 1,
                id_usuario: matricula.id_usuario,
                id_registrador: matricula.id_usuario,
                id_curso: this.idcurso,
                codigo_matricula:matricula.codigo_matricula,
                monto: matricula.monto,
            };
            this.showModal();
            
        },
        async desactivarMatricula(id) {
            try {
                    await MatriculaService.desactivarMatricula(id);
                    this.$swal({
                        icon: "success",
                        title: "Matricula Desactivada",
                        text: "La Matricula ha sido desactivada",
                    }).then(() => {
                        location.reload();
                    });
                } catch (error) {
                    console.error("Error desactivando la Matricula:", error);
                    this.$swal({
                        icon: "error",
                        title: "Error",
                        text: "Hubo un error al desactivar la Matricula.",
                    });
                }
            
        },
        async SuspenderMatricula(id,estado) {
            let titulo="";
            let icon='';
            if(estado=='Activo'){
                titulo='Matricula Suspendida'
                icon='info'
            }
            else
            {
                titulo='Matricula Activada'
                icon='success'
            }
        
            try {
                    await MatriculaService.SuspenderMatricula(id);
                    this.$swal({
                        icon: icon,
                        title: titulo
                    }).then(() => {
                        location.reload();
                    });
                } catch (error) {
                    console.error("Error en la Matricula:", error);
                    this.$swal({
                        icon: "error",
                        title: "Error",
                        text: error,
                    });
                }
            
        },
       
        showModal() {
            const modal = document.getElementById("principalModal");
            modal.classList.add("show");
            modal.style.display = "block";
            modal.setAttribute("aria-modal", "true");
            modal.removeAttribute("aria-hidden");
        },
        closeModal() {
            const modal = document.getElementById("principalModal");
            modal.classList.remove("show");
            modal.style.display = "none";
            modal.setAttribute("aria-hidden", "true");
            modal.removeAttribute("aria-modal");
            this.formErrors = {};
        },
    },
    mounted() {
        this.fetchMatriculas();
        this.fetchAlumnos();
    },
};
</script>

<style scoped>
/* Agrega estilos personalizados si es necesario */
</style>
